/* ResumeThemeSelector.css */
.resume-theme-selector {
  padding: 1.5rem 0;
}

.theme-heading {
  color: #6600CC;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.theme-subheading {
  color: #2d2d2d !important;
  font-size: 0.875rem;
  margin-bottom: 3.5rem;
}

.theme-card {
  position: relative;
  border-radius: 0.75rem;
  border: 2px solid #e5e7eb;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.theme-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(102, 0, 204, 0.15);
  border-color: #d1d5db;
}

.theme-card.selected {
  border-color: #6600CC;
  box-shadow: 0 6px 12px rgba(102, 0, 204, 0.2);
}

.selected-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: #6600CC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.theme-preview {
  height: 67px;
  padding: 1rem;
  background-color: #f9f9f9;
  display: flex;
  scale:1.2;
  align-items: center;
  justify-content: center;
}
@media screen and (max-wdith:600px) {
  .theme-preview {
    position: fixed;
    height: 67px;
    padding: 1rem;
    background-color: #f9f9f9;
    display: flex;
    scale:1.2;
    align-items: center;
    justify-content: center;
    top: 0px;
  }
}

.theme-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}

.theme-info {
  padding: 0.75rem;
  text-align: center;
  background-color: white;
  border-top: 1px solid #f0f0f0;
}

.theme-name {
  font-weight: 600;
  color: #333;
  font-size: 0.875rem;
}

/* Resume preview styles */
.resume-preview {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 0.25rem;
}

/* Classic resume preview */
.classic-preview {
  display: flex;
  flex-direction: column;
}

.header-section {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.name-block {
  height: 0.75rem;
  width: 40%;
  background-color: #6600CC;
  border-radius: 2px;
}

.contact-block {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.contact-block span {
  height: 0.25rem;
  width: 2rem;
  background-color: #e5e7eb;
  border-radius: 2px;
}

.content-section {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 30%;
  background-color: #f3f4f6;
  padding: 0.5rem;
}

.section-titler {
  height: 0.35rem;
  width: 70%;
  background-color: #6600CC;
  margin-bottom: 0.5rem;
  border-radius: 2px;
}

.skill-bar {
  height: 0.25rem;
  width: 85%;
  background-color: #e5e7eb;
  margin-bottom: 0.35rem;
  border-radius: 2px;
}

.main-content {
  flex: 1;
  padding: 0.5rem;
}

.experience-block {
  margin-bottom: 0.5rem;
}

.job-title {
  height: 0.35rem;
  width: 50%;
  background-color: #6600CC;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.job-description {
  height: 0.25rem;
  width: 90%;
  background-color: #e5e7eb;
  border-radius: 2px;
}

/* Modern resume preview */
.modern-preview {
  display: flex;
  flex-direction: column;
}

.modern-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #f8f0ff;
  border-bottom: 2px solid #6600CC;
}

.profile-circle {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: #6600CC;
  margin-right: 0.5rem;
}

.name-title-block {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.title-block {
  height: 0.25rem;
  width: 3rem;
  background-color: #e5e7eb;
  border-radius: 2px;
}

.modern-content {
  padding: 0.5rem;
}

.section-block {
  margin-bottom: 0.5rem;
}

.content-lines {
  display: flex;
  flex-direction: column;
  gap: .15rem;
}

.line {
  height: 0.2rem;
  background-color: #e5e7eb;
  border-radius: 2px;
}

.line:first-child {
  width: 90%;
}

.line:nth-child(2) {
  width: 70%;
}

/* Minimal resume preview */
.minimal-preview {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.minimal-header {
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
}

.center-name {
  height: 0.4rem;
  width: 40%;
  background-color: #333;
  border-radius: 2px;
}

.minimal-divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 0.25rem 0;
}

.minimal-content {
  padding-top: 0.25rem;
}

.content-columns {
  display: flex;
  gap: 0.5rem;
}

.column {
  flex: 1;
}

.minimal-section-title {
  height: 0.3rem;
  width: 50%;
  background-color: #6600CC;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.minimal-item {
  height: 0.25rem;
  width: 90%;
  background-color: #e5e7eb;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

/* Creative resume preview */
.creative-preview {
  display: flex;
  height: 100%;
}

.creative-sidebar {
  width: 35%;
  background-color: #6600CC;
  padding: 0.5rem;
}

.profile-area {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: white;
  opacity: 0.8;
  margin: 0 auto 0.5rem;
}

.sidebar-section {
  padding-top: 0.25rem;
}

.creative-title {
  height: 0.3rem;
  width: 70%;
  background-color: white;
  opacity: 0.8;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.creative-item {
  height: 0.2rem;
  width: 85%;
  background-color: white;
  opacity: 0.5;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.creative-main {
  flex: 1;
  padding: 0.5rem;
}

.creative-name {
  height: 0.4rem;
  width: 50%;
  background-color: #6600CC;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.creative-title-bar {
  height: 0.25rem;
  width: 30%;
  background-color: #6600CC;
  opacity: 0.6;
  margin-bottom: 0.5rem;
  border-radius: 2px;
}

.creative-content {
  padding-top: 0.25rem;
}

.creative-section {
  margin-bottom: 0.5rem;
}

.creative-main-title {
  height: 0.3rem;
  width: 40%;
  background-color: #6600CC;
  margin-bottom: 0.25rem;
  border-radius: 2px;
}

.creative-experience {
  margin-left: 0.25rem;
}

.exp-header {
  height: 0.25rem;
  width: 60%;
  background-color: #333;
  margin-bottom: 0.15rem;
  border-radius: 2px;
}

.exp-content {
  height: 0.2rem;
  width: 90%;
  background-color: #e5e7eb;
  border-radius: 2px;
}