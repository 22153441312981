.callback-popup-overlay {
  position: fixed;
  top: 18px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease;
  padding: 10px; /* Reduced padding for mobile */
}

.callback-popup-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px; /* Slightly reduced for mobile */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Allow scrolling if content overflows */
  max-height: 90vh; /* Limit height on mobile */
  animation: slideIn 0.4s ease;
}

.callback-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px; /* Adjusted padding */
  border-bottom: 1px solid #eee;
  background-color: #6600cc;
}

.callback-popup-header h3 {
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: 18px; /* Smaller header text for mobile */
}

.close-button {
  background: white;
  border: none;
  width: 28px; /* Slightly smaller button */
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.callback-popup-body {
  padding: 15px; /* Reduced padding for mobile */
}

.callback-popup-body p {
  color: #555;
  margin-bottom: 12px;
  font-size: 13px; /* Slightly smaller text */
}

/* Form grid layout */
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px; /* Reduced gap */
}

.form-group {
  margin-bottom: 12px; /* Reduced margin */
}

.form-group label {
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
  display: block;
  font-size: 13px; /* Smaller labels */
}

.form-control {
  height: 38px; /* Slightly smaller inputs */
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 13px;
  padding: 0 10px;
  transition: border-color 0.2s ease;
  width: 100%;
}

.form-control:focus {
  border-color: #6600cc;
  box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.2);
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px; /* Reduced gap */
  margin-top: 8px;
  grid-column: span 2;
}

.submit-button,
.cancel-button {
  padding: 8px 16px; /* Smaller buttons */
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.submit-button {
  background-color: #6600cc;
  color: white;
  border: none;
}

.submit-button:hover {
  background-color: #5500aa;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(102, 0, 204, 0.3);
}

.cancel-button {
  background-color: white;
  color: #6600cc;
  border: 1px solid #6600cc;
}

.cancel-button:hover {
  background-color: #f7f7f7;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .callback-popup-container {
    max-width: 90%; /* Narrower container */
    margin-top: 20px;
  }

  .form-grid {
    grid-template-columns: 1fr; /* Single column layout */
    grid-gap: 10px; /* Smaller gap */
  }

  .callback-popup-header {
    padding: 10px 15px; /* Further reduced padding */
  }

  .callback-popup-header h3 {
    font-size: 16px; /* Even smaller header */
  }

  .close-button {
    width: 24px;
    height: 24px;
  }

  .callback-popup-body {
    padding: 12px; /* Further reduced padding */
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-buttons {
    justify-content: center; /* Center buttons on mobile */
    grid-column: 1;
    flex-wrap: wrap; /* Allow wrapping if needed */
  }

  .submit-button,
  .cancel-button {
    padding: 8px 14px;
    width: 100%; /* Full-width buttons */
    max-width: 200px; /* Limit button width */
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .callback-popup-container {
    max-width: 95%;
    margin-top: 20px;
  }

  .callback-popup-header h3 {
    font-size: 14px;
  }

  .form-control {
    height: 36px;
    font-size: 12px;
  }

  .form-group label {
    font-size: 12px;
  }

  .submit-button,
  .cancel-button {
    font-size: 12px;
    padding: 7px 12px;
  }
}