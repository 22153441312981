/* BannerStyle.css */
:root {
    --primary-color: #6600CC;
    --white: #ffffff;
    --light-gray: #f5f5f5;
    --dark-gray: #1d1d1d;
  }
  
  .ai-banner {
    background-color: var(--white);
    padding-top: 90px;
    padding-bottom: 0px;
    overflow: hidden;
    position: relative;
  }
  
  .banner-title {
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    color: rgba(25, 25, 25, 0.771);
    position: relative;
  }
  
  .banner-title span {
    position: relative; /* Added */
    display: inline-block; /* Ensures it wraps content */
    z-index: 1;
  }
  
  .banner-title span::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 20px;
    background: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 20' preserveAspectRatio='none'><path d='M0,10 C50,-5 150,25 200,10 V20 H0 Z' fill='%236600CC'/></svg>");
    /* background: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 20' preserveAspectRatio='none'><path d='M0,10 C50,-5 150,25 200,10 V20 H0 Z' fill='%23006600'/></svg>"); */

    background-size: cover; 
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: -1;
  }
  
  
  @keyframes bb-swoosh {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
  
  
  .text-primary {
    color: var(--primary-color) !important;
  }
  
  .banner-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2.5rem;
    color: var(--dark-gray);
  }
  
  .banner-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .try-for {
    background-color: #6600CC;
    border-color: var(--primary-color);
    color: var(--white);
    border-radius: 4px;
    padding: 12px 24px;
    transition: all 0.3s ease;
  }

  .try-for:hover{
    color: #6600CC;
    background-color: #fff;
    border: 1px solid #6600CC !important;
  }
  
  .btn-primary:hover {
    background-color: #5500aa;
    border-color: #5500aa;
  }
  
  .btn-outline-dark {
    background-color: transparent;
    border: 1px solid var(--dark-gray);
    color: var(--dark-gray);
    border-radius: 4px;
    padding: 12px 24px;
    transition: all 0.3s ease;
  }
  
  .btn-outline-dark:hover {
    background-color: var(--dark-gray);
    color: var(--white);
  }
  
  .arrow-icon {
    margin-left: 8px;
    font-style: normal;
  }
  
  .dashboard-wrapper {
    position: relative;
    max-width: 100%;
  }
  
  .dashboard-image {
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .dashboard-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .feature-badges {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .feature-badge {
    background-color: var(--white);
    color: var(--dark-gray);
    padding: 8px 15px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  
  .badge-icon {
    color: var(--primary-color);
    font-weight: bold;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: rgba(102, 0, 204, 0.1);
    border-radius: 50%;
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .banner-title {
      font-size: 2.5rem;
    }
    
    .feature-badges {
      right: 0;
    }
  }
  
  @media (max-width: 768px) {
    .banner-title {
      font-size: 2rem;
    }
    
    .banner-buttons {
      flex-direction: column;
      gap: 10px;
    }
    
    .btn {
      width: 100%;
    }
    
    .feature-badges {
      position: static;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      transform: none;
    }
  }