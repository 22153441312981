/* Jobs.css - Styling for the Jobs component */

/* Main Wrapper */
.jobs-wrapper {
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .content-wrapper {
    padding: 2rem 0;
  }
  
  /* Jobs Header */
  .jobs-header {
    text-align: center;
    margin-bottom: 2.5rem;
    padding: 2rem 0;
    background: linear-gradient(135deg, #7224cc, #7226cc);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
  
  .jobs-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: white;
  }
  
  .jobs-subtitle {
    font-size: 1.2rem;
    font-weight: 300;
    opacity: 0.9;
  }
  
  /* Search Container */
  .search-container {
    margin-bottom: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .search-input {
    padding: 1.2rem 1rem;
    border: none;
    font-size: 1rem;
  }
  
  .search-icon-container {
    background-color: white;
    border: none;
    padding-left: 1.2rem;
  }
  
  .search-icon {
    color: #6c757d;
  }
  
  .search-button {
    padding: 0 1.5rem;
    font-weight: 600;
    background-color: #3498db;
    border-color: #3498db;
    transition: all 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
  
  /* Filters Sidebar */
  .filters-sidebar {
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    height: 100%;
  }
  
  .filter-section {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e9ecef;
  }
  
  .filter-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .filter-heading {
    font-size: 1rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .filter-checkbox-group {
    margin-top: 0.75rem;
  }
  
  .custom-checkbox {
    margin-bottom: 0.75rem;
  }
  
  .custom-checkbox label {
    color: #495057;
    font-size: 0.95rem;
  }
  
  .form-check-input:checked {
    background-color: #3498db;
    border-color: #3498db;
  }
  
  .custom-select select {
    padding: 0.6rem;
    border-color: #ced4da;
    color: #495057;
  }
  
  /* Job Cards */
  .jobs-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .job-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .job-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
  
  .job-card-header {
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid #eaeaea;
    position: relative;
  }
  
  .company-logo-container {
    min-width: 70px;
    width: 70px;
    height: 70px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 1.25rem;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eaeaea;
  }
  
  .company-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .job-header-info {
    flex: 1;
  }
  
  .job-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    background: none !important;
  }
  
  .company-name {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
  }
  
  .job-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .job-meta-icon {
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .job-salary-tag {
    background-color: #e9f7ef;
    color: #27ae60;
    padding: 0.5rem 0.75rem;
    border-radius: 20px;
    font-weight: 600;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
  
  .job-card-body {
    padding: 1.5rem;
    border-bottom: 1px solid #eaeaea;
  }
  
  .job-description {
    color: #495057;
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;
  }
  
  .job-highlights {
    margin-top: 1rem;
  }
  
  .highlights-title {
    font-size: 0.95rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.75rem;
  }
  
  .highlights-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .highlights-list li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    color: #495057;
    font-size: 0.9rem;
  }
  
  .highlights-list li:before {
    content: "•";
    color: #3498db;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
  
  .job-card-footer {
    padding: 1.25rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .company-size {
    display: flex;
    align-items: center;
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  .apply-button {
    padding: 0.5rem 1.25rem;
    font-weight: 600;
    background-color: #3498db;
    border-color: #3498db;
    transition: all 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
  
  /* Loading State */
  .jobs-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    text-align: center;
  }
  
  .loading-text {
    margin-top: 1rem;
    color: #6c757d;
    font-size: 1rem;
  }
  
  /* No Jobs Found */
  .no-jobs-container {
    text-align: center;
    padding: 3rem 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .no-jobs-icon {
    color: #6c757d;
    margin-bottom: 1.5rem;
  }
  
  .no-jobs-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .no-jobs-message {
    color: #6c757d;
    margin-bottom: 1.5rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Results Summary */
  .results-summary {
    padding: 0.5rem 0;
  }
  
  .results-count {
    font-size: 0.95rem;
    color: #6c757d;
    margin-bottom: 0;
  }
  
  /* Pagination */
  .pagination-container {
    margin-top: 2rem;
  }
  
  .pagination .page-link {
    color: #3498db;
    border-color: #e9ecef;
    padding: 0.5rem 1rem;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #3498db;
    border-color: #3498db;
  }
  
  .pagination .page-item.disabled .page-link {
    color: #6c757d;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .job-card-header {
      flex-direction: column;
    }
    
    .company-logo-container {
      margin-bottom: 1rem;
    }
    
    .job-salary-tag {
      position: static;
      margin-top: 1rem;
      align-self: flex-start;
    }
  }
  
  @media (max-width: 768px) {
    .jobs-title {
      font-size: 2rem;
    }
    
    .jobs-subtitle {
      font-size: 1rem;
    }
    
    .job-meta {
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .job-card-footer {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
    
    .apply-button {
      width: 100%;
    }
  }
  
  /* Light hover effect on form elements */
  .form-control:focus, 
  .form-select:focus, 
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(52, 152, 219, 0.25);
    border-color: #3498db;
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }


  