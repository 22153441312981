/* PostJob.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Left Panel Styles */
.left-panel {
  background-color: #fff;
  min-height: 70vh;
  /* padding: 20px; */
  position: relative;
}

.hero-content {
  max-width: 90%;
}

.hero-content h1 {
  font-size: 40px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

.sub-heading {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
  line-height: 1.5;
  width: 115%;
}

/* Stats section */
.stats-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  max-width: 115%;
}

.stat-item {
  text-align: center;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 12px;
 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.stat-item h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6600CC;
  margin-bottom: 5px;
}

.stat-item p {
  font-size: 14px;
  color: #555;
  margin: 0;
}

/* Hiring process section */
.hiring-process {
  max-width: 100%;
  margin-top: -40px;
}

.hiring-process h3 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.step-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  flex: 1;
  box-shadow: 0 4px 12px rgba(102, 0, 204, 0.1);
  transition: transform 0.3s ease;
}

.step-card:hover {
  transform: translateY(-5px);
}

.step-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(102, 0, 204, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.step-number {
  display: inline-block;
  background-color: #6600CC;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 20px;
}

.step-card h4 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-top: 15px;
}

/* Right Panel Styles */
.right-panel {
  background-color: #f9f9f9;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  background-color: white;
  margin-top: -115px;
  padding: 30px;
  border-radius: 16px;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 10px 25px rgba(102, 0, 204, 0.08);
}

.form-container h2 {
  font-size: 28px;
  font-weight: 700;
  color: #6600CC;
  margin-bottom: 30px;
  text-align: center;
}

.form-label {
  font-weight: 500;
  color: #444;
  margin-bottom: 8px;
}

.form-control {
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #6600CC;
  box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.2);
}

.forgot-link {
  color: #6600CC;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}

.btn-submit {
  background-color: #6600CC;
  color: white;
  height: 50px;
  border-radius: 8px;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #5500aa;
  color: white;
}

.register-link {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.register-link a {
  color: #6600CC;
  font-weight: 600;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .left-panel, .right-panel {
    padding: 20px;
  }
  
  .hero-content h1 {
    font-size: 32px;
  }
  
  .steps-container {
    flex-direction: column;
    width: 115%;
  }
  
  .step-card {
    margin-bottom: 15px;
  }
  .contents-wrapper{
    padding: 0px 7px;
  }

  .hiring-process h3{
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .post-job-container {
    height: auto;
  }
  
  .contents-wrapper{
    padding: 0px 13px;
  }
  .left-panel {
    min-height: auto;
    padding-bottom: 60px;
  }
  
  .right-panel {
    min-height: auto;
  }
  
  .stats-container {
    flex-direction: column;
    gap: 15px;
  }
  
  .stat-item {
    width: 112%;

  }
  .hiring-process{
    width: 120%;
    padding: 0px 15px;
  }
}