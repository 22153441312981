/* Dashboard.module.css */

.dashboardContainer {
  width: 100%;
  overflow-x: hidden;
  margin: auto;
}

.greetingCard {
  background: #FFFFFF;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.greetingContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

@media (min-width: 768px) {
  .greetingContent {
    flex-direction: row;
    align-items: center;
  }
}

.greetingText {
  font-size: 0.95rem;
  color: #6c5ce7;
  font-weight: bold;
  margin-bottom: 12px;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@media (min-width: 768px) {
  .greetingText {
    margin-bottom: 0;
  }
}

.careerPathBtn {
  background: #6600CC;
  color: #fff;
  border-radius: 8px;
  padding: 8px 15px;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  transition: 0.3s;
  box-shadow: 0 2px 4px rgba(108, 92, 231, 0.3);
  width: 100%;
  max-width: 160px;
}

.careerPathBtn:hover {
  background: #5800b3;
  box-shadow: 0 3px 6px rgba(108, 92, 231, 0.4);
}

.layoutRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.columnLeft, .columnRight {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .columnLeft, .columnRight {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
}

.cardBody {
  padding: 20px;
}

.cardHeader {
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cardTitle {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

@media (min-width: 576px) {
  .metricsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .metricsGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.metricCard {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.badgeContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.metricBadge {
  background-color: #f3e8ff;
  color: #7e22ce;
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 9999px;
}

.progressBadge {
  background-color: #dcfce7;
  color: #22c55e;
  padding: 4px 16px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 9999px;
  display: flex;
  align-items: center;
}

.metricValue {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 12px;
}

.labelContainer {
  display: flex;
  align-items: center;
  color: #4b5563;
}

.labelText {
  font-size: 14px;
  margin-left: 8px;
}

.speedometerContainer {
  max-width: 120px;
  margin: 0 auto;
}

.chartContainer {
  height: 400px;
  width: 100%;
}

@media (max-width: 768px) {
  .chartContainer {
    height: 300px;
  }
}

.learningItem {
  transition: transform 0.2s ease-in-out;
}

.learningItem:hover {
  transform: translateY(-2px);
}

.playsButton {
  background-color: #6600CC;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.playsButton:hover {
  transform: scale(1.1);
}
.body-wrapper {
  width: 100% !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  transform: scale(1) !important;
}

/* Force all direct children of body-wrapper to respect width */
.body-wrapper > * {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

/* Fix container fluid inside body-wrapper */
.body-wrapper .container-fluid {
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  max-width: 100vw !important;
}

/* Ensure rows don't cause horizontal scrolling */
.body-wrapper .row {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

/* Adjust column padding */
.body-wrapper [class*="col-"] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* Make charts responsive */
.responsive-container {
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 768px) {
  .body-wrapper {
    transform: scale(0.98) !important;
    transform-origin: top left;
  }
}

