/* Dream Career Page Styles */
.dream-career-bg {
    background: linear-gradient(to bottom, rgba(101, 28, 166, 0.85) 0%, rgba(95, 85, 182, 0.85) 100%);
    padding: 20px; 
    margin-top: -10px;
    border-radius: 0;
    position: relative;
    overflow: hidden;
}
  
.dream-career-bg::before {
    content: '';
    position: absolute;
    right: -50px;
    width: 200px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 1;
}
  
.dream-career-bg::after {
    content: '';
    position: absolute;
    bottom: -80px;
    left: -80px;
    width: 300px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 1;
}
  
.dream-career-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 40px;
    position: relative;
    z-index: 2;
}
  
.dream-career-title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 40px;
    color: #fff;
    line-height: 1.2;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.highlighted-text {
    color: #FFD700;
    font-weight: 800;
    position: relative;
    display: inline-block;
}
  
.highlighted-text::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #FFD700;
    border-radius: 2px;
}
  
.career-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
}
  
.career-option-item {
    display: flex;
    align-items: center;
    gap: 15px;
    transform: translateX(0);
    transition: transform 0.3s ease;
}
  
.career-option-item:hover {
    transform: translateX(5px);
}
  
.bolt-icon {
    font-size: 24px;
    color: #FFD700;
    filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.3));
}
  
.career-option-button {
    display: inline-block;
    padding: 12px 25px;
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 30px;
    color: #6600CC;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    min-width: 200px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
  
.career-option-button:hover {
    background-color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}
  
.your-dream-job {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}
  
.bell-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    animation: pulse 2s infinite;
}
  
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}
  
.dream-job-title {
    font-size: 1.7rem;
    font-weight: 700;
    color: #FFD700;
    margin-bottom: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
  
/* Right Section */
.dream-career-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-top: -60px;
    margin-right: -30px;
}
  
.video-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.3s ease;
}
  
.video-card:hover {
    transform: translateY(-5px);
}
  
/* Video Container */
.video-container {
    position: relative;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
}
  
.video-overlayi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
  
.play-buttoning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
    box-shadow: 0 5px 15px rgba(102, 0, 204, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
}
  
.play-button:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 25px rgba(102, 0, 204, 0.4);
}
  
.play-button:active {
    transform: scale(0.95);
}
  
.career-video {
    width: 100%;
    display: block;
    border-radius: 12px;
    aspect-ratio: 16/9;
    object-fit: cover;
}
  
.career-video.playing {
    z-index: 3;
}
  
/* Stats Section - Updated to match the image */
.stats-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    margin-top: -60px;  /* Negative margin to overlap with the purple section */
    position: relative;
    z-index: 100;
}
  
.stats-item {
    padding: 10px 15px;
    position: relative;
}
  
.stats-item:first-child::after {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    height: 60%;
    width: 1px;
    background-color: #E0E0E0;
}
  
.stats-number {
    font-size: 2.5rem;
    font-weight: 800;
    color: #6600CC;
    margin-bottom: 5px;
    position: relative;
    display: inline-block;
}
  
.stats-number::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 3px;
    background-color: #6600CC;
    border-radius: 2px;
}
  
.stats-label {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 2px;
}
  
.stats-note {
    font-size: 0.8rem;
    color: #666;
    font-style: italic;
    margin-bottom: 0;
}
  
/* Responsive Styles */
@media (max-width: 992px) {
    .dream-career-left {
        padding-right: 0;
        margin-bottom: 50px;
    }
    
    .dream-career-title {
        font-size: 2.5rem;
    }
}
  
@media (max-width: 768px) {
    .dream-career-bg {
        padding: 40px 0 100px;
    }
    
    .dream-career-title {
        font-size: 2rem;
        margin-bottom: 30px;
    }
    
    .stats-card {
        margin-top: -50px;
    }
    
    .stats-item {
        margin-bottom: 10px;
    }
    
    .play-button {
        width: 60px;
        height: 60px;
    }
    
    .play-button svg {
        width: 24px;
        height: 24px;
    }
    
    .stats-number {
        font-size: 2.2rem;
    }
    
    .stats-label {
        font-size: 0.9rem;
    }
}
  
@media (max-width: 576px) {
    .career-option-button {
        min-width: 180px;
        padding: 10px 20px;
    }
    
    .dream-career-bg {
        padding: 30px 0 80px;
    }
    
    .stats-card {
        margin-top: -40px;
    }
    
    .stats-number {
        font-size: 2rem;
    }
    
    .stats-label {
        font-size: 0.85rem;
    }
    
    .dream-job-title {
        font-size: 1.5rem;
    }
}