:root {
    --primary: #6600CC;
    --primary-light: #F0E6FF;
    --primary-gradient: linear-gradient(135deg, #6600CC, #8033D9);
    --shadow: rgba(102, 0, 204, 0.2);
    --white: #ffffff;
    --gray-light: #F8F5FF;
    --text-dark: #333333;
    --text-light: #666666;
    --border-radius: 20px;
    --animation-speed: 0.2s;
  }
  
  /* Base container */
  .chatbot-container {
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    right: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
  
  /* Toggle button */
  .chat-toggle-button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var(--primary-gradient);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    box-shadow: 0 4px 12px var(--shadow);
    transition: transform var(--animation-speed) ease;
  }
  
  .chat-toggle-button:hover {
    transform: scale(1.05);
  }
  
  /* Chat window */
  .chat-window {
    width: 400px;
    height: 630px;
    background: white;
    border-radius: var(--border-radius);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 24px var(--shadow);
    animation: scale-in var(--animation-speed) forwards;
    border: 1px solid rgba(50, 6, 94, 0.1);
  }
  
  /* Header */
  .chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: var(--gray-light);
    border-bottom: 1px solid rgba(102, 0, 204, 0.1);
  }
  
  .header-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--primary);
    margin-right: 12px;
  }
  
  .header-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-title {
    font-weight: 600;
    color: var(--primary);
    font-size: 16px;
  }
  
  .header-subtitle {
    font-size: 13px;
    color: var(--text-light);
  }
  
  .close-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(102, 0, 204, 0.1); /* Keep the background visible by default */
    color: var(--primary);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color var(--animation-speed) ease;
    opacity: 1; /* Ensure it's fully visible */
}

.close-button:hover {
    background: rgba(102, 0, 204, 0.3); /* Slightly darker on hover */
}
  
  /* Messages container */
  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    background-color: var(--gray-light);
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--gray-light);
  }
  
  .messages-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .messages-container::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 3px;
  }
  
  /* Bot avatar */
  .avatar-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--primary);
    margin-bottom: 2px;
  }
  
  .bot-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .bot-name {
    font-size: 11px;
    color: var(--text-light);
  }
  
  /* User avatar */
  .user-avatar-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--primary-gradient);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Message bubbles */
  .bot-message {
    background-color: #ece3f7;
    color: var(--text-dark);
    border-radius: 16px 16px 16px 0;
    padding: 10px 14px;
    max-width: 70%;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .user-message {
    background: var(--primary-gradient);
    color: var(--white);
    border-radius: 16px 16px 0 16px;
    padding: 10px 14px;
    max-width: 70%;
    font-size: 14px;
    box-shadow: 0 1px 3px var(--shadow);
  }
  
  /* Typing indicator */
  .typing-indicator {
    display: flex;
    align-items: center;
  }
  
  .typing-indicator span {
    height: 7px;
    width: 7px;
    margin: 0 2px;
    background-color: var(--primary);
    border-radius: 50%;
    display: inline-block;
    opacity: 0.7;
    animation: blink 1s infinite;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 0.4; }
    50% { opacity: 1; }
  }
  
  /* Input container */
  .input-container {
    padding: 12px;
    background-color: var(--white);
    border-top: 1px solid rgba(102, 0, 204, 0.1);
  }
  
  .input-form {
    display: flex;
    gap: 8px;
  }
  
  .message-input {
    flex: 1;
    padding: 10px 16px;
    border-radius: 30px;
    border: 1px solid rgba(102, 0, 204, 0.2);
    font-size: 14px;
    outline: none;
    transition: border-color var(--animation-speed) ease, box-shadow var(--animation-speed) ease;
  }
  
  .message-input:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 2px var(--shadow);
  }
  
  .send-button {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--primary-gradient);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px var(--shadow);
    transition: transform var(--animation-speed) ease;
  }
  
  .send-button:hover {
    transform: scale(1.05);
  }
  
  /* Animations */
  @keyframes scale-in {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  /* Responsive layout */
  @media (max-width: 767px) {
    .chatbot-container.open {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    .chat-window {
      width: 100%;
      height: 100%;
      border-radius: 0;
      animation: none;
    }
    
    .close-button {
      width: 36px;
      height: 36px;
    }
  
    .message-input, .send-button {
      height: 42px;
    }
  }