/* Main layout */
.video-interface {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #13121a;
  color: #fff;
  overflow: hidden;
}

/* Loading and error states */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #13121a;
  color: #fff;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 15px;
}

.spinner-small {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-text, .error-text {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.error-text {
  color: #ff4d4f;
  text-align: center;
  padding: 20px;
}

/* Video section */
.video-section {
  flex: 1;
  position: relative;
  background-color: #000;
  height: 100vh;
  overflow: hidden;
}

#vimeo-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Fix for Vimeo player */
.video-section iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

.btn-assessment {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #6600CC;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  z-index: 100;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-assessment:hover {
  background-color: #7711dd;
}

.assessment-completed-badge {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #6600CC;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  z-index: 100;
}

/* Course sidebar */
.course-sidebar {
  width: 380px;
  height: 100vh;
  background-color: #FFFFFF;
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sidebar-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.menu-icon {
  cursor: pointer;
  color: #6600CC;
}

.player-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.player-control-btn {
  background: none;
  border: none;
  color: #6600CC;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-control-btn:hover {
  color: #7711dd;
}

.exit-button {
  background-color: #6600CC;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 7px 14px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.exit-button:hover {
  background-color: #7711dd;
}

.course-title-container {
  margin-top: 10px;
}

.course-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #333333;
  line-height: 1.3;
}

.instructor {
  font-size: 14px;
  color: #6600CC;
  margin: 4px 0 0 0;
}

/* Navigation tabs */
.navigation-tabs {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
}

.tab-button {
  flex: 1;
  background: none;
  border: none;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 0;
  cursor: pointer;
  transition: color 0.2s, border-color 0.2s;
  position: relative;
}

.tab-button.active {
  color: #6600CC;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 3px;
  background-color: #6600CC;
  border-radius: 3px 3px 0 0;
}

/* Tab content */
.tab-content {
  flex: 1;
  overflow-y: auto;
  padding: 0;
}

/* Contents tab */
.contents-tab {
  overflow-y: auto;
}

.module-item {
  border-bottom: 1px solid #e5e5e5;
}

.module-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.module-header:hover {
  background-color: #f5f0ff;
}

.module-indicator {
  margin-right: 15px;
}

.module-number {
  width: 30px;
  height: 30px;
  background-color: #6600CC;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
}

.module-info {
  flex: 1;
}

.module-title {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #333333;
}

.module-meta {
  font-size: 12px;
  color: #777777;
  margin-top: 4px;
}

.expand-icon {
  color: #6600CC;
  transition: transform 0.2s;
}

.video-list {
  padding: 0 0 10px 0;
  background-color: #f9f6ff;
}

.video-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 65px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
}

.video-item:hover {
  background-color: #f0e6ff;
}

.video-item.active {
  background-color: #e6d9ff;
}

.video-item.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #6600CC;
}

.video-title-container {
  flex: 1;
  margin-right: 15px;
}

.video-title {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  display: block;
  margin-bottom: 4px;
}

.video-indicators {
  display: flex;
  align-items: center;
  gap: 10px;
}

.video-duration {
  font-size: 12px;
  color: #777777;
}

.video-progress-indicator {
  font-size: 12px;
  color: #b48fff;
}

.assessment-icon {
  color: #b48fff;
  display: flex;
  align-items: center;
}

.video-actions {
  display: flex;
  align-items: center;
}

.complete-btn {
  background-color: transparent;
  border: 1px solid #6600CC;
  color: #b48fff;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  white-space: nowrap;
}

.complete-btn:hover {
  background-color: #6600CC;
  color: white;
}

.completed-status {
  font-size: 12px;
  color: #b48fff;
  font-weight: 600;
}

/* Transcript tab */
.transcript-tab {
  padding: 15px 20px;
  overflow-y: auto;
  height: 100%;
  background-color: #FFFFFF;
}

.transcript-loading {
  display: flex;
  align-items: center;
  color: #777777;
  font-size: 14px;
}

.transcript-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.transcript-segment {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.transcript-time {
  font-size: 12px;
  color: #6600CC;
  margin-bottom: 5px;
}

.transcript-text {
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
}

.transcript-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #777777;
  font-size: 14px;
}

/* Sidebar footer */
.sidebar-footer {
  padding: 15px 20px;
  border-top: 1px solid #e5e5e5;
  background-color: #FFFFFF;
}

.playback-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.autoplay-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333333;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2a2a3a;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #6600CC;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #6600CC;
}

input:checked + .toggle-slider:before {
  transform: translateX(16px);
}

.playback-speed {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333333;
}

.speed-select {
  background-color: transparent;
  color: #333333;
  border: none;
  padding: 2px 5px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.speed-select option {
  background-color: #FFFFFF;
  color: #333333;
}

/* Fullscreen and player control adjustments */
/* Accommodate Vimeo player in fullscreen mode */
:global(.player-fullscreen),
:global(.vp-fullscreen),
:global(:-webkit-full-screen),
:global(:-moz-full-screen),
:global(:-ms-fullscreen),
:global(:fullscreen) {
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  z-index: 9999 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: #000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Aspect ratio container for responsive video */
.aspect-ratio-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.aspect-ratio-container iframe,
.aspect-ratio-container object,
.aspect-ratio-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Ensure controls remain visible */
:global(.vp-controls) {
  z-index: 99 !important;
  display: flex !important;
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.25s ease !important;
}

/* Make sure control wrappers are fully visible */
:global(.vp-controls-wrapper) {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
}

/* Fix video sizing */
:global(.vp-target),
:global(.vp-video),
:global(.vp-player-layout) {
  width: 100% !important;
  height: 100% !important;
}

/* Responsive adjustments */
@media (max-width: 1600px) {
  .video-section {
    height: 100vh;
  }
}

@media (max-width: 1200px) {
  .course-sidebar {
    width: 350px;
  }
  
  .video-section {
    height: 100vh;
  }
}

@media (max-width: 992px) {
  .video-interface {
    flex-direction: column;
    height: auto;
  }
  
  .video-section {
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
  }
  
  .course-sidebar {
    width: 100%;
    height: auto;
    max-height: 50vh;
  }
}

@media (max-width: 768px) {
  .video-section {
    padding-bottom: 56.25%;
  }
  
  .video-actions {
    display: flex;
  }
  
  .video-item {
    padding: 12px 15px 12px 50px;
  }
}

@media (max-width: 768px) {
  .video-actions {
    display: none;
  }
  
  .video-item {
    padding: 12px 15px 12px 50px;
  }
}