.clif-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 10px;
}

.circle1 {
  width: 45px;
  height: 45px;
  background-color: transparent; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  animation: pulse 1.2s infinite;
}

.circle1:nth-child(1) {
  background-color: #4285f4; /* Blue */
  animation-delay: 0s;
}

.circle1:nth-child(2) {
  background-color: #fbbc05; /* Yellow */
  animation-delay: 0.2s;
}

.circle1:nth-child(3) {
  background-color: #34a853; /* Green */
  animation-delay: 0.4s;
}

.circle1:nth-child(4) {
  background-color: #ea4335; /* Red */
  animation-delay: 0.6s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
