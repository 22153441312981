.hire-professionals-wrapper {
  background-color: #f2f5f9;
  position: relative;
  overflow: hidden;
  padding: 20px 47px;
}

.hire-prof-container {
  display: flex;
  flex-direction: row;
  height: 600px;
}

/* Left Content Side */
.hire-prof-content {
  flex: 0 0 50%;
  padding: 80px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hire-prof-content h1 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  color: #4D4D4D;
  margin-bottom: 20px;
}

.hire-prof-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

.hiring-types {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.hiring-types span {
  font-size: 16px;
  color: #333;
}

.dot-separator {
  margin: 0 10px;
  color: #6600CC;
  font-weight: bold;
}

.actions-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.request-callback {
  background-color: white;
  color: #6600CC;
  border: 1px solid #6600CC;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.request-callback:hover {
  background-color: #f7f7f7;
}

.post-job {
  background-color: transparent;
  color: #6600CC;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 12px 0;
  position: relative;
}

.post-job:hover {
  text-decoration: underline;
  color: #006600;
}

/* Right Video Side */
.hire-prof-video {
  flex: 0 0 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imags-container {
  width: 100%;
  height: 70%;
  position: relative;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Stats Bar */
.stats-bar {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stat-item {
  text-align: center;
}

.stat-item h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.stat-item p {
  font-size: 16px;
  color: #555;
  margin: 0;
  line-height: 1.4;
}

/* Responsive styles */
@media (max-width: 992px) {
  .hire-prof-container {
    flex-direction: column;
    height: auto;
  }
  
  .hire-prof-content, .hire-prof-video {
    flex: 0 0 100%;
  }
  
  .hire-prof-content {
    padding: 40px 7px;
  }
  
  .image-container {
    height: 400px;
    width: 100%;
  }
  
  .stats-bar {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .hire-professionals-wrapper {
    padding: 20px;
  }
  
  .hire-prof-content h1 {
    font-size: 32px;
  }
  
  .hire-prof-content p {
    font-size: 16px;
  }
  
  .hiring-types {
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .dot-separator {
    display: none;
  }
  
  .actions-buttons {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .request-callback, .post-job {
    width: 100%;
    text-align: center;
  }
  
  .image-container {
    height: 300px;
  }
  
  .stats-bar {
    flex-direction: column;
    height: auto;
    padding: 15px;
  }
  
  .stat-item {
    margin: 10px 0;
  }
}