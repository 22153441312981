/* CareerPrep.css - Fixed layout to match image */
.career-prep-section {
  padding: 80px 0;
  background-color: #fff;
}



.header-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
}

.section-headings {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #4D4D4D;
}

.section-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 25px;
  color: #555;
}

.counseling-btn {
  background-color: #6600CC;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin-bottom: 30px;
}

.counseling-btn:hover {
  background-color: #0055aa;
}

/* Rating cards positioning */
.rating-cards-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 10px;
}

.rating-card {
  background-color: #FFD970;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.program-support {
  background-color: #FFB0B0;
}

.rating-value {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #333;
}

.rating-label {
  font-size: 14px;
  margin: 0;
  color: #555;
}

/* Feature sections */
.career-feature {
  margin-bottom: 50px;
}

.feature-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-content {
  padding: 0 20px;
}

.feature-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #6600CC;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #6600CC;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 8px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .header-row {
    flex-direction: column;
  }
  
  .rating-cards-container {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .rating-card {
    width: 48%;
  }
  
  .feature-content {
    padding: 30px 0 0 0;
  }
}