.video-meeting-container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #2b5876, #4e4376);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", "Arial", sans-serif;
  }

  .meeting-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 20px;
  }

  .meeting-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    color: #ffffff;
  }

  .meeting-header h3 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
  }

  .participant-count {
    background: rgba(0, 123, 255, 0.3);
    color: white;
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .participants-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }

  .participant-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    overflow: hidden;
    aspect-ratio: 16/9;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .participant-card:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  .participant-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #1a1e2e;
  }

  .participant-video.hidden {
    display: none;
  }

  .avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #3a3d4c, #1a1e2e);
    color: #ffffff;
    font-size: 48px;
    font-weight: 600;
  }

  .participant-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    color: #ffffff;
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .participant-name {
    font-weight: 600;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .participant-status {
    display: flex;
    gap: 12px;
  }

  .status-indicator {
    font-size: 16px;
    transition: all 0.3s ease;
  }

  .status-indicator.active {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }

  .status-indicator.inactive {
    opacity: 0.5;
  }

  .meeting-controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 24px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    margin-top: auto;
  }

  .control-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: none;
    background: rgba(255, 255, 255, 0.15);
    color: white;
    font-size: 28px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .control-button:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  }

  .control-button.active {
    background: rgba(40, 167, 69, 0.8);
    color: #ffffff;
  }

  .control-button.active:hover {
    background: rgba(40, 167, 69, 0.9);
  }

  .control-button:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
    opacity: 0.6;
  }

  .control-button.disconnect {
    background: rgba(255, 193, 7, 0.8);
  }

  .control-button.disconnect:hover:not(:disabled) {
    background: rgba(255, 193, 7, 0.9);
  }

  .control-button.end {
    background: rgba(220, 53, 69, 0.8);
    color: #ffffff;
  }

  .control-button.end:hover:not(:disabled) {
    background: rgba(220, 53, 69, 0.9);
  }

  .button-icon {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .control-label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .processing-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .control-button.processing {
    pointer-events: none;
  }

  .permission-alert {
    display: flex;
    align-items: center;
    background: rgba(255, 248, 231, 0.9);
    border-left: 5px solid #ffca2c;
    padding: 16px 24px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 15px;
    color: #856404;
    backdrop-filter: blur(8px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .alert-icon {
    font-size: 26px;
    margin-right: 15px;
  }

  .disconnected-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 30px;
  }

  .status-message {
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 450px;
  }

  .status-message h3 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
  }

  .action-buttons {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
  }

  .btn {
    padding: 12px 24px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .btn-secondary {
    background: linear-gradient(45deg, #6c757d, #495057);
    color: #ffffff;
  }

  .btn-secondary:hover {
    background: linear-gradient(45deg, #5a6268, #3d4246);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }

  .error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: linear-gradient(135deg, #2b5876, #4e4376);
  }

  .error-content {
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 450px;
  }

  .error-icon {
    font-size: 50px;
    margin-bottom: 20px;
    display: block;
  }
  
  /* Review form styles */
  .review-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
  }
  
  .review-form {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    padding: 40px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .review-form h4 {
    color: #6600CC;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .star-rating {
    display: flex;
    gap: 10px;
    font-size: 30px;
  }
  
  .star {
    cursor: pointer;
    color: #d1d1d1;
    transition: color 0.2s ease;
  }
  
  .star:hover, .star.active {
    color: #ffc107;
  }

  @media (max-width: 768px) {
    .participants-grid {
      grid-template-columns: 1fr;
    }

    .control-button {
      width: 65px;
      height: 65px;
      font-size: 22px;
    }

    .button-icon {
      font-size: 24px;
    }

    .meeting-controls {
      gap: 12px;
      padding: 16px;
    }
    
    .review-form {
      padding: 20px;
      width: 95%;
    }
  }