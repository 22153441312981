/* Skills.module.css */
.skillsContent {
  padding: 20px;
  background-color: #ffffff;
  min-height: calc(100vh - 60px);
  color: #333333;
}

.skillsInnerContent {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}

/* Back button styling */
.backBtn {
  background: transparent;
  border: none;
  color: #333333;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: color 0.2s;
}

.backBtn:hover {
  color: #6600CC;
}

/* Main title styling */
.mainTitle {
  font-size: 24px;
  font-weight: 600;
  color: #6600CC;
  margin-bottom: 0;
}

/* Add to channel button */
.addBtn {
  background-color: #f0f0f0;
  color: #333333;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 14px;
  transition: all 0.2s;
}

.addBtn:hover {
  background-color: #e0e0e0;
  color: #000000;
}

/* Skill description */
.skillDescription {
  color: #666666;
  line-height: 1.6;
  font-size: 14px;
}

.certificateInfo {
  margin-top: 16px;
}

.historyLink {
  color: #6600CC;
  text-decoration: none;
}

.historyLink:hover {
  text-decoration: underline;
}

.relatedTopics {
  margin-top: 20px;
}

.relatedTopics h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333333;
}

.topicTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: #f0f0f0;
  color: #555555;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
}

.tag:hover {
  background-color: #6600CC;
  color: #FFFF;
}

/* Skill Analysis Section */
.skillAnalysisSection {
  margin-top: 30px;
}

.analysisCard {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.analysisHeader {
  padding: 16px 20px;
  background-color: #f0f0f0;
}

.analysisHeader h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #333333;
}

.analysisContent {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skillLevelIndicator {
  flex: 1;
}

.levelLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.levelLabels span {
  font-size: 12px;
  color: #666666;
}

.skillChart {
  margin-bottom: 8px;
}

.chartLine {
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  margin-bottom: 8px;
}

.yellowLine {
  position: absolute;
  height: 100%;
  width: 70%;
  background: linear-gradient(90deg, #6600CC, #9966FF);
  border-radius: 4px;
}

.percentileLabels {
  display: flex;
  justify-content: space-between;
}

.percentileLabels span {
  font-size: 12px;
  color: #666666;
}

.measureSection {
  text-align: center;
  padding-left: 20px;
}

.measureSection h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333333;
}

.measureNowBtn {
  background-color: #6600CC;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.measureNowBtn:hover {
  background-color: #5500aa;
}

/* Courses Tabs */
.coursesTabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.tab {
  padding: 12px 20px;
  cursor: pointer;
  color: #666666;
  font-weight: 500;
  transition: all 0.2s;
}

.tab:hover {
  color: #6600CC;
}

.tabActive {
  color: #6600CC;
  border-bottom: 2px solid #6600CC;
  padding-bottom: 10px;
}

/* Level Section */
.levelSection {
  margin-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.levelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
}

.levelTitle {
  display: flex;
  align-items: center;
}

.levelTitle h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-left: 8px;
  color: #333333;
}

.rotatedIcon {
  transform: rotate(180deg);
  transition: transform 0.3s;
  color: #6600CC;
}

.progressIndicator {
  display: flex;
  align-items: center;
  gap: 12px;
}

.watchedInfo {
  font-size: 12px;
  color: #666666;
}

.progressBar {
  width: 100px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: #6600CC;
}

.progressPercentage {
  font-size: 12px;
  color: #666666;
}

.sectionDescription {
  padding: 0 0 16px 28px;
  color: #666666;
  font-size: 14px;
}

.hidden {
  display: none;
}

/* Course Card */
.courseCard {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 28px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.courseType {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 8px;
  font-size: 11px;
  border-radius: 3px;
  letter-spacing: 0.5px;
}

.courseThumbnail {
  height: 180px;
  overflow: hidden;
}

.courseThumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courseInfo {
  padding: 16px;
}

.courseTitle {
  font-size: 16px;
  font-weight: 600;
  color: #6600CC;
  margin-bottom: 12px;
}

.courseAuthor {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 13px;
  color: #666666;
}

.courseMetaTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.metaTag {
  background-color: #f0f0f0;
  color: #555555;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
  display: inline-block;
}

.relevanceTag {
  background-color: #6600CC;
  color: #FFFF;
}

/* Additional footer elements */
.courseFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid #f0f0f0;
}

.courseRating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ratingStars {
  color: #6600CC;
}

.ratingCount {
  font-size: 12px;
  color: #666666;
}

.courseDuration {
  font-size: 12px;
  color: #666666;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* No courses placeholder */
.noCoursesPlaceholder {
  text-align: center;
  padding: 40px 20px;
  color: #666666;
}

.placeholderIcon {
  font-size: 48px;
  color: #cccccc;
  margin-bottom: 16px;
}

.placeholderText {
  font-size: 16px;
  margin-bottom: 20px;
}

.exploreBtn {
  background-color: #6600CC;
  color: #FFFF;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.exploreBtn:hover {
  background-color: #5500aa;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .analysisContent {
    flex-direction: column;
    align-items: stretch;
  }
  
  .measureSection {
    padding-left: 0;
    margin-top: 20px;
  }
  
  .courseCard {
    margin-left: 0;
  }
  
  .levelHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .progressIndicator {
    margin-top: 8px;
    margin-left: 28px;
  }
}

.courseCard {
  background: #fff;
  overflow: hidden;
}

.courseThumbnail {
  height: 100%;
}

.courseInfo {
  display: flex;
  flex-direction: column;
}

.progressIndicator {
  background: rgba(255, 255, 255, 0.95);
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
}

.watchedInfo {
  font-size: 0.75rem;
  color: #666;
  line-height: 1.2;
}

.progressBar {
  width: 100%;
  height: 5px;
  background: #e9ecef;
  border-radius: 2.5px;
  overflow: hidden;
  margin: 3px 0;
}

.progressFill {
  height: 100%;
  background: #6600CC;
  border-radius: 2.5px;
  transition: width 0.3s ease;
}

.progressPercentage {
  font-size: 0.85rem;
  font-weight: 600;
  color: #333;
}

.courseTitle {
  font-size: 1.25rem;
  line-height: 1.3;
}

.courseMetaTags {
  align-items: center;
}

.metaTag {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
}

.courseActions {
  padding-top: 1rem;
}

.continueBtn {
  padding: 0.375rem 1rem;
  font-size: 0.9rem;
  background-color: #6600CC;
  border-color: #6600CC;
}

.continueBtn:hover {
  background-color: #5500aa;
  border-color: #5500aa;
}

/* Playlist styles */
.playlistSection {
  max-height: 300px;
  overflow-y: auto;
}

.moduleItem {
  padding-left: 10px;
}

.module-title {
  font-size: 1rem;
  color: #333;
}

.videoList {
  padding-left: 20px;
}

.video-item {
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  border-radius: 4px;
  position: relative;
}

.video-item:hover {
  background-color: #f0e6ff;
  transform: translateY(-1px);
}

.video-item:active {
  transform: translateY(0);
}

.enrolling {
  opacity: 0.7;
  position: relative;
}

.enrolling::after {
  content: "Enrolling...";
  position: absolute;
  right: 10px;
  color: #6600CC;
  font-size: 0.8rem;
  font-weight: bold;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.video-item:hover .video-title {
  color: #6600CC;
}

.video-title {
  font-size: 0.9rem;
  color: #555;
  transition: color 0.2s ease;
}

.duration {
  font-size: 0.85rem;
}

.video-progress-indicator {
  font-size: 0.85rem;
  font-weight: 500;
  color: #6600CC;
}

/* Expand Details button */
.expandDetailsBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #6600CC;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 16px;
  margin-top: 12px;
  transition: color 0.2s;
}

.expandDetailsBtn:hover {
  color: #5500aa;
}

.expandDetails {
  display: flex;
  justify-content: center;
}