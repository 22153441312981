/* NetworkingSessions.css */
.networking-sessions-section {
    padding: 80px 0;
    background-color: #f5f2ff;
  }
  
  .networking-content .title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #4D4D4D;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 18px;
    color: #666;
  }
  
  .bullet-point {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #6600CC;
    margin-right: 15px;
    opacity: 0.2;
  }
  
  .companies-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }
  
  .company-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }
  
  .logo-item {
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .logo-item img {
    max-height: 100%;
    max-width: 100px;
    object-fit: contain;
  }
  
  .networking-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .networking-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  .navigation-buttons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .nav-btn {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 25px;
    padding: 8px 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .nav-btn.prev {
    padding-right: 20px;
  }
  
  .nav-btn.next {
    padding-left: 20px;
    color: #6600CC;
  }
  
  .nav-btn:hover {
    background-color: #6600CC;
    color: white;
  }
  
  /* For the hackathon section */
  .hackathons-section {
    padding: 80px 0;
    background-color: white;
  }
  
  .hackathon-content .title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #4D4D4D;
  }
  
  .hackathon-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .hackathon-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  @media (max-width: 992px) {
    .networking-content {
      margin-bottom: 40px;
    }
  }