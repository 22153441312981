.officials-section {
    background-color: #fff; /* White background */
    padding: 60px 0;
  }
  
  .testimonial-card {
    background-color: #f9f9f9; /* Light background for cards */
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 8px 20px rgba(102, 0, 204, 0.2); /* Shadow with theme color */
  }
  
  .image-wrappers {
    position: relative;
    margin-bottom: 20px;
  }
  
  .image-wrappers img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 3px solid #6600cc; /* Theme color border */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .testimonial-quote {
    color: #666; /* Lighter text for the quote */
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    font-style: italic;
  }
  
  .testimonial-name {
    color: #6600cc; /* Theme color for the name */
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .testimonial-title {
    color: #28a745; /* Green accent for the title */
    font-size: 1rem;
    font-weight: 500;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .testimonial-card {
      padding: 20px;
    }
  
    .testimonial-quote {
      font-size: 1rem;
    }
  
    .testimonial-name {
      font-size: 1.1rem;
    }
  
    .testimonial-title {
      font-size: 0.9rem;
    }
  }