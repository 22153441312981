.skill-assessment-wrapper {
    background-color: #f4f6f9;
}

.skill-assessment-container {
    padding-top: 100px !important;
}

.skill-assessment-title {
    letter-spacing: -1px;
}

.skill-course-card {
    transition: all 0.3s ease;
}

.skill-course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(102, 0, 204, 0.1) !important;
}