/* JobBoards.css */
.job-boards-section {
    padding: 50px 0;
    background-color: #f5f2ff;
  }
  
  .job-boards-content h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #4D4D4D;
  }
  
  .job-boards-content p {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
  }
  
  .feature-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
  }
  
  .feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #e0d1ff;
    color: #6600CC;
    margin-right: 12px;
    font-weight: bold;
  }
  
  .download-report-btn {
    display: inline-block;
    padding: 12px 25px;
    background-color: #6600CC;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .download-report-btn:hover {
    background-color: #5500aa;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(102, 0, 204, 0.3);
  }
  
  .job-boards-image {
    position: relative;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
  }
  
  .dashboard-img {
    border-radius: 5px;
    max-width: 100%;
    height: auto;
  }
  
  /* Career Transitions Section */
  .career-transitions-section {
    padding: 60px 0;
  }
  
  .career-transitions-section h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #333;
  }
  
  .career-cards-container {
    position: relative;
    padding: 20px 0;
  }
  
  .career-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .career-card-wrapper {
    padding: 15px;
    transition: all 0.3s ease;
  }
  
  .career-card {
    background-color: white;
    border-radius: 10px;
    padding: 25px 15px;
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    height: 100%;
    transition: transform 0.3s ease;
  }
  
  .career-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(102, 0, 204, 0.2);
  }
  
  .profile-images {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px;
    border: 3px solid #6600CC;
  }
  
  .profile-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .career-card h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  .initial-role, .current-role {
    margin-bottom: 10px;
  }
  
  .initial-role p, .current-role p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #666;
  }
  
  .company-logo {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }
  
  .company-logo img {
    max-height: 100%;
    max-width: 100px;
  }
  
  .transition-arrow {
    margin: 15px 0;
    display: flex;
    justify-content: center;
  }
  
  .arrow-down {
    width: 30px;
    height: 30px;
    background-color: #6600CC;
    position: relative;
    border-radius: 50%;
  }
  
  .arrow-down:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }
  
  .navigation-button {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
  }
  
  .prev-btn, .next-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .prev-btn:hover, .next-btn:hover {
    border-color: #6600CC;
    color: #6600CC;
  }
  
  .prev-btn span, .next-btn span {
    margin: 0 5px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 991px) {
    .job-boards-content {
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 767px) {
    .career-card-wrapper {
      max-width: 270px;
      margin: 0 auto;
    }
  }