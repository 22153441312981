/* WorldClass Component Styles */
.worldclass-section {
    padding: 60px 0;
    background-color: #f8f9fa;
  }
  
  .section-heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #4D4D4D;
    margin-bottom: 50px;
  }
  
  /* Flip Card Container - Contains the entire flipping card */
  .flip-card-container {
    perspective: 1000px;
    height: 500px;
    width: 100%;
  }
  
  /* The actual flip card that rotates */
  .flip-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
  }
  
  /* Flipped state */
  .flip-card.flipped {
    transform: rotateY(180deg);
  }
  
  /* Common styles for both sides of the card */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Front of the card */
  .flip-card-front {
    background-color: #fff;
  }
  
  .flip-card-back {
    background-color: #fff;
    /* color: white; */
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
  }
  
  /* Domain card styling (front side) */
  .domain-card {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .domain-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .icon-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    background-color: rgba(102, 0, 204, 0.1);
    color: #6600CC;
    transition: all 0.3s ease;
  }
  
  .flip-card:hover .icon-wrapper {
    transform: scale(1.05);
    background-color: rgba(102, 0, 204, 0.2);
  }
  
  .domain-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #4D4D4D;
    margin-bottom: 10px;
  }
  
  /* Subcategory items on front side */
  .subcategory-preview {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .subcategory-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    border-left: 3px solid #6600CC;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .subcategory-item:hover {
    background-color: #f0f0f0;
  }
  
  .arrow-icon {
    color: #6600CC;
    opacity: 0.7;
  }
  
  .subcategory-item:hover .arrow-icon {
    opacity: 1;
  }
  
  /* Flip prompt */
  .flip-prompt {
    text-align: center;
    padding: 15px 0 5px;
    color: #6600CC;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  
  .flip-card:hover .flip-prompt {
    opacity: 1;
  }
  
  /* Back of card styling */
  .back-header {
    padding: 25px 20px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .back-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
  }
  
  .back-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .back-subcategory {
    margin-bottom: 25px;
  }
  
  .back-subtitle {
    font-weight: 600;
    background-color: #f8f9fa;
    border-left: 3px solid #6600CC;
    font-size: 1.2rem;
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .topic-list {
    list-style: none;
    padding-left: 5px;
    margin: 0;
  }
  
  .topic-list li {
    padding: 8px 10px;
    font-size: 14px;
    position: relative;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .topic-list li:before {
    content: "•";
    margin-right: 8px;
    color: #750ddc;
  }
  
  /* Back button */
  .back-footer {
    padding: 15px 20px;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .btn-flip-back {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #6600CC;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
  }
  
  .btn-flip-back:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .back-icon {
    transform: rotate(180deg);
    margin-right: 5px;
  }
  
  /* Partner Slider Styles */
  .partner-heading {
    font-size: 2.2rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 40px;
  }
  
  .partner-slider-container {
    overflow: hidden;
    position: relative;
    margin: 0 -15px;
    padding: 20px 0;
  }
  
  .partner-slider {
    display: flex;
    transition: transform 0.5s ease;
    padding: 1px 0;
  }
  
  .partner-logo {
    flex: 0 0 200px;
    height: 80px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.3s ease;
  }
  
  .partner-logo img {
    max-width: 80%;
    max-height: 60px;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .partner-logo:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
  }
  
  .partner-logo:hover img {
    filter: grayscale(0);
    opacity: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .domain-title, .back-title {
      font-size: 1.6rem;
    }
    
    .flip-card-container {
      height: 520px;
    }
  }
  
  @media (max-width: 992px) {
    .section-heading,
    .partner-heading {
      font-size: 2rem;
    }
    
    .partner-logo {
      flex: 0 0 180px;
    }
    
    .flip-card-container {
      height: 550px;
    }
  }
  
  @media (max-width: 768px) {
    .section-heading,
    .partner-heading {
      font-size: 1.8rem;
    }
    
    .flip-card-container {
      height: 480px;
      margin-bottom: 30px;
    }
    
    .partner-logo {
      flex: 0 0 150px;
      height: 70px;
    }
    
    .icon-wrapper {
      width: 70px;
      height: 70px;
    }
    
    .domain-title, .back-title {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .worldclass-section {
      padding: 40px 0;
    }
    
    .section-heading,
    .partner-heading {
      font-size: 1.5rem;
    }
    
    .flip-card-container {
      height: 520px;
    }
    
    .subcategory-item {
      padding: 10px;
    }
  }