/* CareerFairs.css */

.career-fairs-section {
  padding: 20px 0 0px;
  background-color: #fff;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #292929;
}

.section-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Main content row */
.main-content-row {
  display: flex;
  flex-wrap: wrap;
}

/* Stats Cards Styling */
/* Stats Row Styling */
.stats-row {
  margin-bottom: 25px;
  width: 100%;
}

/* Stat Card Styling */
.stat-card {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  height: 100%;
  margin-bottom: 15px; /* Default margin for mobile */
}

.stat-card h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.stat-card p {
  font-size: 0.9rem;
  margin: 0;
}

.yellow-card {
  background-color: #FFE082;
}

.pink-card {
  background-color: #FFCDD2;
}

.purple-card {
  background-color: #E1BEE7;
}

/* Desktop View (Rely on Bootstrap's col-md-4) */
@media (min-width: 768px) {
  .stats-row {
    display: flex; /* Ensure flexbox for alignment */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 20px; /* Space between cards */
  }

  .stat-card {
    margin-bottom: 0; /* Remove margin-bottom in desktop */
    flex: 1 1 0; /* Equal width for all cards */
  }

  /* Ensure Bootstrap's col-md-4 works */
  .stats-row > div[class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .stats-row {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 20px; /* Space between cards in mobile */
  }

  .stat-card {
    margin-bottom: 0; /* Remove margin-bottom since gap handles spacing */
  }
}

/* Features List Styling */
.features-list {
  margin-bottom: 30px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.feature-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6600CC;
  margin-right: 12px;
  margin-top: 7px;
  flex-shrink: 0;
}

.feature-item p {
  margin: 0;
  font-size: 0.95rem;
  color: #444;
}

/* Recruiters Section Styling */
/* Recruiters Section Styling */
.recruiters-section {
  margin-top: 30px;
  padding: 25px;
  background-color: #f8f8f8; /* Light background */
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.recruiters-section h4 {
  font-size: 1.3rem;
  color: #292929;
  margin-bottom: 25px;
  font-weight: 600;
  text-align: center;
}

.company-logos {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.logo-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to two lines if needed */
  gap: 30px; /* Space between logos */
  justify-content: center;
  max-width: 1200px; /* Limits width to control line breaks */
}

.company-logo {
  width: 100px; /* Larger width for visibility */
  height: 60px; /* Larger height */
  object-fit: contain; /* Maintains aspect ratio */
  scale: 1.7;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Hover effect */
}

.company-logo:hover {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .logo-container {
    gap: 20px; /* Slightly reduce gap */
  }

  .company-logo {
    width: 100px; /* Slightly smaller logos */
    height: 50px;
  }
}

@media (max-width: 768px) {
  .logo-container {
    gap: 15px;
  }

  .company-logo {
    width: 90px;
    height: 45px;
  }
}

@media (max-width: 576px) {
  .recruiters-section {
    padding: 15px;
  }

  .logo-container {
    gap: 10px;
  }

  .company-logo {
    width: 70px; /* Still larger than original */
    height: 35px;
  }
}

.view-all-link {
  margin-top: 10px;
}

.view-all-link a {
  color: #6600CC;
  font-weight: 500;
  text-decoration: none;
  font-size: 0.95rem;
}

.view-all-link a:hover {
  text-decoration: underline;
}

/* Video Testimonial Section */
.video-testimonial-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -100px;
}

.video-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f3f0f8;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
  overflow: hidden;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.play-button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border: none;
}

.career-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.speaker-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f8f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.speaker-details {
  flex: 1;
}

.speaker-details h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #292929;
}

.speaker-details p {
  margin: 3px 0 0;
  font-size: 0.8rem;
  color: #555;
}

.logos-strip {
  display: flex;
  align-items: center;
}

.tcs-logo, .tcs-50-logo {
  display: inline-block;
  background-color: #dbdbdb;
  color: #333;
  font-size: 0.7rem;
  padding: 2px 6px;
  margin-left: 5px;
  border-radius: 3px;
}

.navigation-controls {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
}

.nav-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #6600CC;
  font-size: 0.9rem;
  padding: 5px;
  cursor: pointer;
}

.nav-button:hover {
  text-decoration: underline;
}

.nav-button span {
  margin: 0 4px;
}

/* Media Queries */
@media (max-width: 992px) {
  .video-testimonial-container {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  
  .company-logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 2rem;
  }
}