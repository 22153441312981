/* Main Layout Styles */
.page-wrapper {
    background-color: #f5f7fb;
    min-height: 100vh;
    padding: 20px;
  }
  
  .body-wrapper {
    padding: 20px;
  }
  
  /* Card Styles */
  .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
    margin-bottom: 25px;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-header {
    background-color: #fff;
    border-bottom: 2px solid rgba(115, 102, 255, 0.1);
    padding: 20px;
  }
  
  .card-header h3 {
    color: #7366ff;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
  }
  
  .card-body {
    padding: 25px;
  }
  
  /* Form Styles */
  .course-form .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    color: #2c323f;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .form-control {
    border: 1px solid #e6edef;
    border-radius: 5px;
    padding: 12px 15px;
    transition: all 0.3s;
  }
  
  .form-control:focus {
    border-color: #7366ff;
    box-shadow: 0 0 0 0.2rem rgba(115, 102, 255, 0.25);
  }
  
  select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M0 2l4 4 4-4z' fill='%237366ff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 30px;
    appearance: none;
  }
  
  /* Button Styles */
  .default__button {
    background-color: #7366ff;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    padding: 12px 25px;
    transition: all 0.3s ease;
  }
  
  .default__button:hover {
    background-color: #5b4cc4;
    transform: translateY(-2px);
  }
  
  .default__button:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
  
  /* Table Styles */
  .table {
    width: 100%;
    margin-bottom: 0;
  }
  
  .table thead th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #7366ff;
    color: #2c323f;
    font-weight: 600;
    padding: 15px;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  
  .table tbody td {
    border-bottom: 1px solid #e6edef;
    color: #2c323f;
    padding: 15px;
    vertical-align: middle;
  }
  
  .table tbody tr:hover {
    background-color: rgba(115, 102, 255, 0.05);
  }
  
  /* Alert Styles */
  .alert {
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .alert-danger {
    background-color: #fff0f0;
    border-left: 4px solid #dc3545;
    color: #dc3545;
  }
  
  .alert-success {
    background-color: #f0fff0;
    border-left: 4px solid #28a745;
    color: #28a745;
  }
  
  .alert-info {
    background-color: #f0f7ff;
    border-left: 4px solid #0dcaf0;
    color: #0dcaf0;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .page-wrapper {
      padding: 10px;
    }
    
    .card-header h3 {
      font-size: 1.2rem;
    }
    
    .default__button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
    
    .table thead th {
      font-size: 0.8rem;
    }
  }