/* Dashboard.css */
.notification-list {
    max-height: 500px;
    overflow-y: auto;
}

.notification-item {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.notification-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.btn {
    transition: all 0.3s ease;
}

.btn:hover {
    opacity: 0.9;
    transform: scale(1.05);
}

.card {
    overflow: hidden;
}

.card-header h5 {
    font-weight: 600;
}