/* Testimonials.css */
.testimonials-section {
    padding-top: 20px;
    background-color: #fff;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #4D4D4D;
    padding-top: 50px;
    position: relative;
}
  
  .testimonial-container {
    margin-bottom: 80px;
  }
  
  .testimonial-video {
    position: relative;
    margin-bottom: 30px;
  }
  
  .video-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(102, 0, 204, 0.1);
  }
  
  .video-wrapper img {
    width: 100%;
    transition: transform 0.3s ease;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .video-play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #6600CC;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .video-play-btn:hover {
    background-color: #5500aa;
    transform: scale(1.1);
  }
  
  .testimonial-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .nav-button {
    background: none;
    border: none;
    color: #6600CC;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: all 0.3s ease;
  }
  
  .nav-button:hover {
    color: #5500aa;
  }
  
  .nav-button.prev i {
    margin-right: 8px;
  }
  
  .nav-button.next i {
    margin-left: 8px;
  }
  
  .testimonial-content {
    position: relative;
    padding: 20px 30px;
  }
  
  .quote-mark {
    font-size: 40px;
    color: #6600CC;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 20px;
  }
  
  .testimonial-text {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 30px;
  }
  
  .testimonial-author h4 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  
  .testimonial-author p {
    font-size: 1rem;
    color: #777;
    margin-bottom: 15px;
  }
  
  .company-logos img {
    max-height: 40px;
    max-width: 120px;
  }
  
  /* Hiring Partners Section */
  .hiring-partners {
    background-color: #f9f9f9;
    padding: 10px 0;
  }
  
  .partner-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    transition: all 0.3s ease;
  }
  
  .partner-logo:hover {
    transform: translateY(-5px);
  }
  
  .partner-logo img {
    max-height: 60px;
    max-width: 100%;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .partner-logo:hover img {
    opacity: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .testimonial-content {
      padding: 20px 15px;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }
    
    .testimonial-text {
      font-size: 1rem;
    }
    
    .partner-logo {
      height: 80px;
    }
  }