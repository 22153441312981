/* PDFViewer.css */

/* Main PDF container with preview panel layout */
.pdf-main-container {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 200px);
  transition: all 0.3s ease;
}

.pdf-main-container.with-preview {
  margin-left: 150px;
}

/* Preview panel */
.preview-panel {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 150px;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transform: translateX(-150px);
  transition: transform 0.3s ease;
}

.with-preview .preview-panel {
  transform: translateX(0);
}

.preview-title {
  text-align: center;
  padding: 8px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  font-size: 14px;
  color: #495057;
}

.thumbnail-container {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: all 0.2s ease;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thumbnail-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.thumbnail-container.active {
  border-color: #0d6efd;
}

.thumbnail-number {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 10px;
  z-index: 5;
}

.thumbnail-page {
  width: 100%;
  height: auto;
}

/* PDF container */
.pdf-container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

/* PDF page styling */
.pdf-page {
  margin: 20px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: white;
  transition: transform 0.2s ease;
}

.pdf-page:hover {
  transform: translateY(-2px);
}

/* Controls styling */
.pdf-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.navigation-controls,
.zoom-controls {
  display: flex;
  align-items: center;
}

.control-btn,
.zoom-btn,
.preview-toggle {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #dee2e6;
  color: #495057;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn:hover,
.zoom-btn:hover,
.preview-toggle:hover {
  background-color: #f1f3f5;
  color: #212529;
}

.control-btn:disabled,
.zoom-btn:disabled,
.preview-toggle:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.preview-toggle {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  padding: 0;
}

.preview-toggle.active {
  background-color: #e7f1ff;
  color: #0d6efd;
  border-color: #0d6efd;
}

.prev-btn {
  margin-right: 10px;
}

.next-btn {
  margin-left: 10px;
}

.page-indicator {
  padding: 8px 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 14px;
}

.page-indicator span {
  font-weight: 600;
}

.zoom-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 5px;
}

.reset-zoom {
  width: auto;
  padding: 0 12px;
  font-size: 14px;
}

/* Navigation hints */
.nav-hint {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.3);
  font-size: 36px;
  transition: all 0.2s ease;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 10;
}

.nav-hint:hover {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.8);
}

.nav-hint-left {
  left: 20px;
}

.nav-hint-right {
  right: 20px;
}

/* Instructions banner */
.instructions-banner {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(13, 110, 253, 0.1);
  border: 1px solid rgba(13, 110, 253, 0.3);
  color: #0d6efd;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 14px;
  z-index: 20;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.instructions-banner.visible {
  opacity: 1;
  transform: translateX(-50%);
}

.instructions-banner.hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px);
}

.close-instructions {
  background: none;
  border: none;
  color: #0d6efd;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 20px;
  height: 20px;
}

/* Loading spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #6c757d;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(13, 110, 253, 0.2);
  border-radius: 50%;
  border-top-color: #0d6efd;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error message */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  max-width: 500px;
  padding: 30px;
  background-color: #fff3f3;
  border: 1px solid #ffcfcf;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.error-icon {
  font-size: 48px;
  color: #dc3545;
  margin-bottom: 20px;
}

.error-message {
  font-size: 18px;
  color: #dc3545;
}

/* Mobile navigation controls */
.mobile-controls {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.mobile-nav-btn {
  width: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #495057;
}

.mobile-page-indicator {
  font-size: 16px;
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 768px) {
  .pdf-controls {
    flex-direction: column;
  }

  .navigation-controls,
  .zoom-controls {
    width: 100%;
    justify-content: center;
  }

  .zoom-controls {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #dee2e6;
  }

  .nav-hint {
    display: none;
  }

  .mobile-controls {
    display: flex;
  }

  .pdf-container {
    border-radius: 0;
  }

  .preview-panel {
    width: 100%;
    height: 150px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: translateY(150px);
    border-top: 1px solid #dee2e6;
    border-right: none;
    z-index: 1000;
  }

  .with-preview .preview-panel {
    transform: translateY(0);
  }

  .with-preview {
    margin-left: 0;
    margin-bottom: 150px;
  }

  .thumbnail-container {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
  }
}

/* Interactive navigation cursor areas */
.pdf-container::before,
.pdf-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40%;
  z-index: 5;
}

.pdf-container::before {
  left: 0;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 18l-6-6 6-6"/></svg>'),
    w-resize;
}

.pdf-container::after {
  right: 0;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>'),
    e-resize;
}
