/* Main Layout */
/* .main-wrapper {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.page-wrapper {
    flex: 1;
    background-color: #f9f9fd;
    position: relative;
} */

.career-dashboard {
  padding: 20px 0;
  padding-top: 35px;
}

/* Dashboard title */
.dashboard-title {
  color: #444;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.7rem;
}

.speedometer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 200px;
  height: 200px;
  position: relative;
  transition: all 0.3s ease;
}

.gauge-animate {
  transition: stroke-dasharray 1s ease-in-out;
}

.needle {
  transform-origin: 150px 150px;
}

.needle-animate {
  transition: transform 1s ease-in-out;
}

.digital-progress-container {
  padding: 1rem 0;
}

.card {
  border: 1px solid rgba(102, 0, 204, 0.1);
  box-shadow: 0 4px 8px rgba(156, 103, 187, 0.622);
}

.card-body {
  padding: 1.5rem;
}

.card-body h3 {
  color: #565458;
}

.badge {
  font-weight: 500;
  padding: 0.5em 1em;
}
/* Custom range slider styling */
.form-range::-webkit-slider-thumb {
  background: #6600cc;
}

.form-range::-moz-range-thumb {
  background: #6600cc;
}

.form-range::-ms-thumb {
  background: #6600cc;
}

.form-range::-webkit-slider-runnable-track {
  background: #e9ecef;
}

.form-range::-moz-range-track {
  background: #e9ecef;
}

.form-range::-ms-track {
  background: #e9ecef;
}

.badge {
  padding: 0.5rem 1rem;
  font-weight: 500;
}

/* Search & Controls */
.search-wrapper {
  width: 240px;
}

.form-control {
  border: 1px solid rgba(102, 0, 204, 0.15);
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  box-shadow: 0 2px 5px rgba(102, 0, 204, 0.205);
  transition: all 0.25s ease;
}

.form-control:focus {
  border-color: #6600cc;
  box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.237);
}

.view-toggle .btn {
  padding: 7px 10px;
}

/* Milestone Button Container */

.milestone-container {
  position: sticky;
  top: 70px; /* This should match your header height */
  padding: 15px 0;
  background-color: #f9f9fd;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.milestone-container.scrolled {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.milestone-buttons {
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 25px;
  box-shadow: 0 3px 12px rgba(102, 0, 204, 0.243);
  position: relative;
  overflow: hidden;
}

/* Individual Milestone Button */
.milestone-btn {
  background-color: transparent;
  color: #555;
  border: none;
  padding: 15px 0;
  font-weight: 500;
  font-size: 0.92rem;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  margin: 0;
  flex: 1;
  max-width: 80px;
  z-index: 1;
}

/* Hover effect - background glow */
.milestone-btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(102, 0, 204, 0.07);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.4s ease, height 0.4s ease;
  z-index: -1;
}

.milestone-btn:hover:before {
  width: 120%;
  height: 200%;
}

.milestone-btn:hover {
  color: #6600cc;
  transform: translateY(-2px);
}

/* Active state */
.milestone-btn.active {
  color: #6600cc;
  font-weight: 600;
  background-color: rgba(102, 0, 204, 0.05);
  box-shadow: 0 4px 8px rgba(102, 0, 204, 0.255);
}

/* Bottom indicator for active state */
.milestone-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #6600cc;
  transition: width 0.3s ease;
}

.milestone-btn:hover:after {
  width: 40%;
  left: 30%;
}

.milestone-btn.active:after {
  width: 100%;
}

/* View Toggle Buttons */
.btn-outline-primary {
  color: #6600cc;
  border-color: rgba(102, 0, 204, 0.3);
  background-color: white;
  transition: all 0.25s ease;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary.active {
  background-color: #6600cc;
  border-color: #6600cc;
  color: white;
  transform: translateY(-1px);
}

/* Program List */
.program-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.program-item {
  background-color: white;
  border: 1px solid rgba(102, 0, 204, 0.1);
  color: #444343;
  padding: 12px 15px;
  transition: all 0.25s ease;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(102, 0, 204, 0.221);
}

.program-item:hover {
  background-color: rgba(102, 0, 204, 0.03);
  border-color: rgba(102, 0, 204, 0.2);
  transform: translateX(3px);
}

.program-item.active {
  background-color: #6600cc;
  border-color: #6600cc;
  color: white;
  box-shadow: 0 4px 12px rgba(102, 0, 204, 0.259);
}

.program-icon {
  font-size: 18px;
  opacity: 0.9;
}

.program-label {
  font-weight: 500;
}

/* Content Card */
.content-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.186);
  transition: all 0.3s ease;
  background-color: white;
  height: 100%;
}

.content-card:hover {
  box-shadow: 0 8px 22px rgba(102, 0, 204, 0.08);
}

.content-header {
  padding: 18px 22px;
  border-bottom: 1px solid rgba(102, 0, 204, 0.1);
  border-radius: 12px 12px 0 0;
}

.content-title {
  color: #6600cc;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.3rem;
}

.content-body {
  padding: 22px;
}

/* Timeline Section */
.timeline-section {
  margin-bottom: 25px;
}

.timeline-item {
  background-color: white;
  padding: 18px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(102, 0, 204, 0.236);
  height: 100%;
  transition: all 0.3s ease;
  border: 1px solid rgba(102, 0, 204, 0.05);
}

.timeline-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(102, 0, 204, 0.243);
  border-color: rgba(102, 0, 204, 0.1);
}

/* Goals Section */
.goals-section {
  margin-bottom: 25px;
}

.primary-goal {
  background-color: rgba(102, 0, 204, 0.03);
  border: 1px solid rgba(102, 0, 204, 0.08);
  padding: 18px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.primary-goal:hover {
  background-color: rgba(102, 0, 204, 0.05);
  border-color: rgba(102, 0, 204, 0.12);
}

.goal-list,
.focus-list,
.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.goal-item,
.focus-item,
.activity-item {
  background-color: white;
  border: 1px solid rgba(102, 0, 204, 0.08);
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
  padding-left: 28px;
}

.goal-item:before,
.focus-item:before,
.activity-item:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background-color: #6600cc;
  border-radius: 50%;
}

.goal-item:hover,
.focus-item:hover,
.activity-item:hover {
  transform: translateX(3px);
  background-color: rgba(102, 0, 204, 0.02);
  border-color: rgba(102, 0, 204, 0.15);
}

/* Program content styles with theme colors */
.program-content {
  background: white;
}

.covers-section {
  background: linear-gradient(
    135deg,
    rgba(102, 0, 204, 0.03),
    rgba(102, 0, 204, 0.08)
  );
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 2.5rem;
  box-shadow: 0 4px 12px rgba(102, 0, 204, 0.08);
  border: 1px solid rgba(102, 0, 204, 0.1);
}

.covers-content {
  color: #2d2d2d;
  font-size: 1.05rem;
  font-weight: 400;
}

.skills-section {
  margin-bottom: 2.5rem;
}

.section-header {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid rgba(102, 0, 204, 0.15);
  color: #6600cc;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.3px;
}
.skill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.skill-card {
  background: white;
  border: 1px solid rgba(102, 0, 204, 0.12);
  border-radius: 10px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.skill-card:hover {
  box-shadow: 0 6px 16px rgba(102, 0, 204, 0.08);
  transform: translateY(-2px);
  border-color: rgba(102, 0, 204, 0.25);
}

.skill-icon {
  background: rgba(102, 0, 204, 0.05);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.skill-card:hover .skill-icon {
  background: rgba(102, 0, 204, 0.1);
}

.skill-icon svg {
  color: #6600cc;
}

.skill-content {
  flex: 1;
}

.skill-title {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 1.05rem;
  margin-bottom: 8px;
  line-height: 1.3;
}

.skill-description {
  color: #666666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

/* Custom scrollbar for webkit browsers */
.program-content::-webkit-scrollbar {
  width: 8px;
}

.program-content::-webkit-scrollbar-track {
  background: rgba(102, 0, 204, 0.05);
}

.program-content::-webkit-scrollbar-thumb {
  background: rgba(102, 0, 204, 0.2);
  border-radius: 4px;
}

.program-content::-webkit-scrollbar-thumb:hover {
  background: rgba(102, 0, 204, 0.3);
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

/* Program View */
.program-view > div {
  margin-bottom: 25px;
}

.content-box {
  background-color: rgba(102, 0, 204, 0.02);
  border: 1px solid rgba(102, 0, 204, 0.08);
  padding: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.content-box:hover {
  background-color: rgba(102, 0, 204, 0.04);
  border-color: rgba(102, 0, 204, 0.12);
}

/* Section Titles */
.section-title {
  color: #6600cc;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.15rem;
  position: relative;
  padding-left: 12px;
}

.section-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 16px;
  background-color: #6600cc;
  border-radius: 2px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .career-dashboard {
    padding: 15px 0;
  }

  .circular-progress-container {
    padding: 15px;
    margin-bottom: 15px;
  }

  .content-body {
    padding: 15px;
  }

  .milestone-buttons {
    overflow-x: auto;
    justify-content: flex-start;
  }

  .milestone-btn {
    padding: 10px 12px;
    white-space: nowrap;
    min-width: 100px;
  }

  .search-wrapper {
    width: 180px;
    /* color: rgba(208, 80, 80, 0.628); */
  }

  .content-title {
    font-size: 1.15rem;
  }

  .section-title {
    font-size: 1.05rem;
  }

  .dashboard-title {
    font-size: 1.5rem;
  }
  .milestone-container {
    top: 60px;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(102, 0, 204, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(102, 0, 204, 0.5);
}
