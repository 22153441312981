/* MetricBoxes.css */
.metric-container {
    display: flex;
    gap: 24px;
    padding: 24px;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .metric-box {
    min-width: 256px;
    padding: 24px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .metric-box:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .metric-box:active {
    transform: scale(0.98);
  }
  
  .metric-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .metric-info {
    flex-grow: 1;
  }
  
  .metric-title {
    font-size: 14px;
    color: #4B5563;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .metric-value {
    font-size: 24px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 8px;
  }
  
  .metric-growth {
    font-size: 12px;
    color: #6B7280;
  }
  
  .metric-icon-wrapper {
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .metric-icon {
    width: 32px;
    height: 32px;
  }
  
  /* Color variants */
  .metric-box-blue .metric-icon-wrapper {
    background-color: #EFF6FF;
  }
  
  .metric-box-blue .metric-icon {
    color: #2563EB;
  }
  
  .metric-box-green .metric-icon-wrapper {
    background-color: #ECFDF5;
  }
  
  .metric-box-green .metric-icon {
    color: #059669;
  }
  
  .metric-box-purple .metric-icon-wrapper {
    background-color: #F5F3FF;
  }
  
  .metric-box-purple .metric-icon {
    color: #7C3AED;
  }
  
  .metric-box-orange .metric-icon-wrapper {
    background-color: #FFF7ED;
  }
  
  .metric-box-orange .metric-icon {
    color: #EA580C;
  }
  
  /* Accessibility */
  .metric-box:focus {
    outline: 2px solid #2563EB;
    outline-offset: 2px;
  }
  
  /* Scrollbar customization */
  .metric-container::-webkit-scrollbar {
    height: 6px;
  }
  
  .metric-container::-webkit-scrollbar-track {
    background: #F3F4F6;
    border-radius: 3px;
  }
  
  .metric-container::-webkit-scrollbar-thumb {
    background: #D1D5DB;
    border-radius: 3px;
  }
  
  .metric-container::-webkit-scrollbar-thumb:hover {
    background: #9CA3AF;
  }
  
  /* Media query for better mobile support */
  @media (max-width: 640px) {
    .metric-container {
      padding: 16px;
      gap: 16px;
    }
  
    .metric-box {
      min-width: 220px;
      padding: 16px;
    }
  }