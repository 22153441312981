.tech-topics-section {
    background: linear-gradient(135deg, #6600cc 0%, #fff 100%); /* Gradient background with theme colors */
    padding: 60px 0;
    position: relative;
    overflow: hidden;
  }
  
  .tech-topics-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-wrapper {
    position: relative;
    max-width: 100%;
  }
  
  .image-wrapper img {
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .image-wrapper img:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  /* Floating Tech Icons */
  .tech-icon {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .tech-icon:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  .tech-icon.cloud {
    top: 10%;
    left: 10%;
    color: #6600cc; /* Theme color */
  }
  
  .tech-icon.python {
    top: 20%;
    right: 10%;
    color: #28a745; /* Green accent */
  }
  
  .tech-icon.aws {
    bottom: 30%;
    right: 15%;
    color: #6600cc; /* Theme color */
  }
  
  .tech-icon.angular {
    bottom: 20%;
    left: 15%;
    color: #ff5733; /* Orange for contrast */
  }
  
  .tech-icon.js {
    bottom: 10%;
    right: 20%;
    color: #28a745; /* Green accent */
  }
  
  .tech-topics-text {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .tech-topics-title {
    color: #4D4D4D; /* Dark text for contrast */
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  
  .tech-topics-description {
    color: #666; /* Lighter text for description */
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 30px;
  }
  
  .browse-courses-btn {
    background-color: #6600cc; /* Theme color */
    border: none;
    padding: 10px 25px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 25px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .browse-courses-btn:hover {
    background-color: #28a745; /* Green accent on hover */
    transform: translateY(-2px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .tech-topics-title {
      font-size: 2rem;
    }
  
    .tech-topics-description {
      font-size: 1rem;
    }
  
    .tech-topics-image {
      margin-bottom: 30px;
    }
  
    .tech-icon {
      display: none; /* Hide floating icons on smaller screens for clarity */
    }
  }