/* ChatbotStyles.css */
:root {
    --primary-color: #6600cc;
    --primary-light: #8833dd;
    --primary-dark: #5500aa;
    --accent-green: #42b883;
    --accent-green-light: #5acb97;
    --background-color: #ffffff;
    --text-color: #333333;
    --text-light: #666666;
    --text-lighter: #999999;
    --border-color: #e0e0e0;
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);
    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
    --radius-xl: 24px;
    --radius-round: 50%;
    --transition: all 0.3s ease;
  }
  
  /* Chatbot Container & Toggle Button */
  .chatbot-container {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 1000;
  }
  
  .chatbot-toggle-button {
    width: 70px;
    height: 70px;
    border-radius: var(--radius-round);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 6px 18px rgba(102, 0, 204, 0.5), 0 0 15px rgba(102, 0, 204, 0.3); /* Enhanced shadow on hover */
    transition: var(--transition);
    cursor: pointer;
    border: none;
    position: relative;
    overflow: hidden;
    animation: pulse 2s infinite ease-in-out; /* Subtle pulse animation */
  }
  
  .chatbot-toggle-button::before {
    display: none;
  }
  
  .chatbot-toggle-button:hover {
    transform: scale(1.1);
    
  }
  
  .chatbot-toggle-button:hover::before {
    opacity: 1; /* Brighten overlay on hover */
  }
  
  .chatbot-toggle-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--radius-round);
    transition: var(--transition);
  }
  
  .chatbot-toggle-button:hover img {
    filter: brightness(1.1); /* Slightly brighten the image on hover */
  }
  
  /* Pulse Animation */
  @keyframes pulse {
    0% {
      box-shadow: 0 4px 12px rgba(102, 0, 204, 0.3), 0 0 10px rgba(102, 0, 204, 0.2);
    }
    50% {
      box-shadow: 0 4px 16px rgba(102, 0, 204, 0.4), 0 0 15px rgba(102, 0, 204, 0.25);
    }
    100% {
      box-shadow: 0 4px 12px rgba(102, 0, 204, 0.3), 0 0 10px rgba(102, 0, 204, 0.2);
    }
  }
  
  /* Chatbot Window */
  .chatbot-window {
    background-color: var(--background-color);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-lg);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: var(--transition);
    border: 1px solid var(--border-color);
  }
  
  /* Header */
  .chatbot-header {
    /* background: linear-gradient(135deg, var(--primary-color), var(--primary-light)); */
    background-color: #6600cc;
    padding: 15px 20px;
    position: relative;
    border-bottom: none;
  }
  
  .chatbot-header::after {
    display: none;
  }
  
  .chatbot-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .chatbot-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo-img {
    height: 24px;
    filter: brightness(0) invert(1);
  }
  
  .logo-text {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .close-button {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white !important;
    width: 32px;
    height: 32px;
    border-radius: var(--radius-round);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .close-button:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: rotate(90deg);
    color: #9665c8 !important;
  }
  
  /* Body */
  .chatbot-body {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--background-color);
  }
  
  /* Footer */
  .chatbot-footer {
    background-color: var(--background-color);
    border-top: 1px solid var(--border-color);
    padding: 8px 12px;
  }
  
  .chatbot-nav {
    display: flex;
    justify-content: space-around;
  }
  
  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 8px;
    border-radius: var(--radius-sm);
    color: var(--text-lighter);
    cursor: pointer;
    transition: var(--transition);
    background: none;
    border: none;
    width: 33.333%;
  }
  
  .nav-button:hover {
    color: var(--primary-color);
    background-color: rgba(102, 0, 204, 0.05);
  }
  
  .nav-button-active {
    color: var(--primary-color);
    font-weight: 500;
  }
  
  .nav-icon {
    margin-bottom: 2px;
  }
  
  .nav-label {
    font-size: 12px;
  }
  
  /* Home Section */
  .home-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background: none;
  }
  
  .home-header {
    position: relative;
    display: flex;
    padding: 20px 20px 0px 20px;
    justify-content: space-between;
    border-bottom: none; /* Remove any border that might be causing the division */
    background-color: #6600cc;
  }
  
  .home-header-text {
    max-width: 75%;
    padding-right: 10px;
  }


  
  .home-title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .home-subtitle {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    font-weight: 400;
  }
  
  .home-avatar {
    width: 80px;
  height: 80px;
  border-radius: var(--radius-round);
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-shadow: var(--shadow-md);
  align-self: center; /* Better vertical alignment */
  margin-right: 5px; /* Add a bit of margin */
  }
  
  .home-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .home-content {
    flex: 1;
    padding: 0 8px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  overflow-y: auto;
  background-color: var(--background-color);
  }
  
  .faq-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px; /* Reduced spacing */
  }
  
  .faq-card {
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: var(--transition);
    text-align: left;
  }
  
  .faq-card:hover {
    box-shadow: var(--shadow-md);
    border-color: var(--primary-color);
    transform: translateY(-2px);
  }
  
  .faq-question {
    color: var(--text-color);
    font-weight: 500;
    padding-right: 12px;
  }
  
  .faq-arrow {
    color: var(--primary-color);
    opacity: 0.7;
    transition: var(--transition);
  }
  
  .faq-card:hover .faq-arrow {
    opacity: 1;
    transform: translateX(4px);
  }
  
  .blog-button {
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: var(--transition);
    text-align: left;
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .blog-button:hover {
    box-shadow: var(--shadow-md);
    border-color: var(--accent-green);
    color: var(--accent-green);
  }
  
  .contact-button {
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: var(--transition);
    text-align: left;
    width: 100%;
    margin-top: auto; /* This will push it to the bottom if there's space */
    margin-bottom: 0; /* Remove default bottom margin */
  }
  
  .contact-button:hover {
    box-shadow: var(--shadow-md);
    border-color: var(--primary-color);
    transform: translateY(-2px);
  }
  
  .contact-title {
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .contact-subtitle {
    color: var(--text-lighter);
    font-size: 0.9rem;
  }
  
  /* FAQ Detail Section */
  .faq-detail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--background-color);
  }
  
  .faq-detail-header {
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .back-button {
    display: flex;
    align-items: center;
    color: var(--text-color);
    background: none;
    border: none;
    padding: 6px 12px;
    border-radius: var(--radius-sm);
    transition: var(--transition);
    font-weight: 500;
  }
  
  .back-button:hover {
    color: var(--primary-color);
    background-color: rgba(102, 0, 204, 0.05);
  }
  
  .faq-detail-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .faq-title {
    color: var(--primary-color);
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .help-title {
    color: var(--accent-green);
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .faq-answer {
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .faq-image-container {
    border-radius: var(--radius-md);
    overflow: hidden;
    box-shadow: var(--shadow-md);
    margin-top: -57px;
  }
  
  .faq-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Help Section */
  .help-section-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden; 
  }
  
  .help-list {
    padding: 20px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 0; /* Prevent extra padding at bottom */
  }
  
  .help-card {
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: var(--transition);
    text-align: left;
    width: 100%;
  }
  
  .help-card:hover {
    box-shadow: var(--shadow-md);
    border-color: var(--accent-green);
    transform: translateY(-2px);
  }
  
  .help-card-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .help-icon-container {
    width: 40px;
    height: 40px;
    border-radius: var(--radius-sm);
    background-color: rgba(66, 184, 131, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .help-icon {
    color: var(--accent-green);
  }
  
  .help-card-title {
    color: var(--text-color);
    font-weight: 500;
  }
  
  .help-card-arrow {
    color: var(--accent-green);
    opacity: 0.7;
    transition: var(--transition);
  }
  
  .help-card:hover .help-card-arrow {
    opacity: 1;
    transform: translateX(4px);
  }
  
  .help-detail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--background-color);
  }
  
  .help-detail-header {
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .help-detail-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .help-detail-title {
    color: var(--accent-green);
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .help-detail-text {
    color: var(--text-color);
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  .help-step-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
  }
  
  .help-step {
    display: flex;
    gap: 12px;
    padding: 12px;
    border-radius: var(--radius-md);
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
  }
  
  .help-step-number {
    width: 24px;
    height: 24px;
    border-radius: var(--radius-round);
    background-color: var(--accent-green);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem;
  }
  
  .help-step-content {
    flex: 1;
  }
  
  .help-step-title {
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .help-step-description {
    color: var(--text-light);
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .help-image-container {
    border-radius: var(--radius-md);
    margin-top: -57px;
    overflow: hidden;
    box-shadow: var(--shadow-md);
  }
  
  .help-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Chat Section */
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--background-color);
  }
  
  .chat-messages {
    flex: 1;
    padding: 12px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .message {
    display: flex;
    gap: 12px;
    max-width: 85%;
  }

  .message-list {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .message-user {
    align-self: flex-end;
    flex-direction: row-reverse;
  }
  
  .message-bot {
    align-self: flex-start;
    padding-bottom: -1px;
  }
  
  .message-avatar {
    width: 36px;
    height: 36px;
    border-radius: var(--radius-round);
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .message-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .message-content {
    padding: 3px 7px;
    border-radius: var(--radius-md);
    position: relative;
    box-shadow: var(--shadow-sm);
  }

  .message-content-user {
    background-color: var(--primary-color);
    color: white;
    border-bottom-right-radius: 4px;
  }

  .message-content-bot {
    background-color: #f0f0f0;
    color: var(--text-color);
    border-bottom-left-radius: 4px;
  }
  
  .message-user .message-content {
    background-color: var(--primary-color);
    color: white;
    border-bottom-right-radius: 4px;
  }
  
  .message-bot .message-content {
    background-color: var(--border-color);
    color: var(--text-color);
    border-bottom-left-radius: 4px;
  }
  
  .message-text {
    line-height: 1.5;
    font-size: 0.95rem;
    margin-bottom: 4px;
  }
  
  .message-time {
    font-size: 0.75rem;
    text-align: right;
    opacity: 0.8;
    display: block;
  }
  
  .typing-indicator {
    display: flex;
    gap: 4px;
    padding: 12px 16px;
    border-radius: var(--radius-md);
    background-color: #f0f0f0;
    width: fit-content;
    align-items: center;
  }
  
  .typing-indicator span {
    width: 8px;
    height: 8px;
    border-radius: var(--radius-round);
    background-color: var(--text-lighter);
    display: inline-block;
    animation: typing 1.4s infinite ease-in-out both;
  }

  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typingAnimation {
    0%, 60%, 100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-5px);
    }
  }
  
  .chat-input-container {
    padding: 16px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
  }
  
  .chat-input-form {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
  }
  
  .chat-input {
    flex: 1;
    padding: 12px 16px;
    border-radius: var(--radius-md);
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    font-size: 0.95rem;
    color: var(--text-color);
    transition: var(--transition);
  }
  
  .chat-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.1);
  }
  
  .chat-input-actions {
    position: absolute;
    right: 12px;
    display: flex;
    gap: 8px;
  }
  
  .chat-input-action {
    width: 36px;
    height: 36px;
    border-radius: var(--radius-round);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-lighter);
    background: none;
    border: none;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .chat-input-action:hover {
    color: var(--primary-color);
    background-color: rgba(102, 0, 204, 0.05);
  }
  
  .chat-send-button {
    width: 44px;
    height: 44px;
    border-radius: var(--radius-round);
    background: var(--primary-color);
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transition);
    position: relative;
    overflow: hidden;
  }
  
  
  .chat-send-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), transparent);
    border-radius: var(--radius-round);
  }

  .chat-send-button:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-sm);
  }
  
  /* Dark Mode */
  .dark-mode {
    --background-color: #1a1a1a;
    --text-color: #f0f0f0;
    --text-light: #cccccc;
    --text-lighter: #999999;
    --border-color: #333333;
  }
  
  .dark-mode .message-bot .message-content {
    background-color: #333333;
    color: #f0f0f0;
  }
  
  .dark-mode .faq-card,
  .dark-mode .help-card,
  .dark-mode .blog-button,
  .dark-mode .contact-button,
  .dark-mode .help-step {
    background-color: #222222;
  }
  
  .dark-mode .chat-input {
    background-color: #222222;
  }
  
  /* Responsive Styles */
  @media (min-width: 768px) {
    .chatbot-window {
      width: 400px;
      height: 600px;
    }
  }
  
  @media (max-width: 767px) {
    .chatbot-window {
      border-radius: 0;
      height: 100% !important;
      width: 100% !important;
      display: flex;
      flex-direction: column;

    }
    .chatbot-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    

    .home-content{
      padding: 20px;
    }

    .home-container {
      flex: 1;
    }  
    
    .help-card-title,
    .faq-question {
      font-size: 0.95rem;
    }
    
    .home-title {
      font-size: 1.3rem;
    }
    
    .home-subtitle {
      font-size: 1.1rem;
      color: white;
    }

    .home-header{
      padding: 30px 20px 20px 20px;
      border-top: transparent;
    }

    .help-image-container, .faq-image-container {
      margin-top: 10px; /* Reduce from 30px to 10px */
    }

    .faq-container {
      margin-bottom: 10px;
    }
  }