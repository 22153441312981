/* MentorProfileDetail.module.css */

:root {
    --primary: #6600CC;
    --primary-light: rgba(102, 0, 204, 0.1);
    --primary-medium: rgba(102, 0, 204, 0.2);
    --primary-dark: #5500AA;
    --white: #fff;
    --light-gray: #f8f9fa;
    --gray: #6c757d;
    --border-light: rgba(0, 0, 0, 0.08);
    --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 4px 12px rgba(102, 0, 204, 0.15);
    --shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.12);
    --transition: all 0.3s ease;
    --border-radius-sm: 0.25rem;
    --border-radius-md: 0.5rem;
    --border-radius-lg: 15px;
    --border-radius-xl: 20px;
  }
  
  /* Main Layout */
  .mainWrapper {
    background-color: var(--light-gray);
    min-height: 100vh;
  }
  
  .bodyWrapper {
    display: flex;
  }
  
  .pageWrapper {
    flex: 1;
    margin-top: 1rem;
    padding: 1rem;
  }
  
  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  /* Profile Card */
  .profileCard {
    background-color: var(--white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    border: none;
    overflow: hidden;
    margin-bottom: 2rem;
  }
  
  .profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: var(--white);
  }
  
  .profileInfo {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
  }
  
  .profileName {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }
  
  .profileSubtitle {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }
  
  .profileEmail {
    color: var(--gray);
    margin-bottom: 0.5rem;
  }
  
  .profileImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid var(--white);
    box-shadow: var(--shadow-md);
    transition: var(--transition);
  }
  
  .profileImage:hover {
    transform: scale(1.05);
  }
  
  /* Tab Navigation */
  .tabNavigation {
    display: flex;
    border-bottom: 1px solid var(--border-light);
    margin-bottom: 1.5rem;
    padding: 0;
    overflow-x: auto;
  }
  
  .tabItem {
    list-style: none;
    margin-right: 1rem;
  }
  
  .tabButton {
    padding: 0.75rem 1.25rem;
    background: transparent;
    border: none;
    color: var(--gray);
    font-weight: 500;
    transition: var(--transition);
    position: relative;
    cursor: pointer;
  }
  
  .tabButton:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: transparent;
    transition: var(--transition);
  }
  
  .tabButtonActive {
    color: var(--primary);
  }
  
  .tabButtonActive:after {
    background-color: var(--primary);
  }
  
  /* Card Styles */
  .card {
    background-color: var(--white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    border: none;
    margin-bottom: 1.5rem;
    transition: var(--transition);
  }
  
  .card:hover {
    box-shadow: var(--shadow-md);
  }
  
  .cardBody {
    padding: 1.5rem;
  }
  
  .cardTitle {
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .cardText {
    color: var(--gray);
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  /* Information Items */
  .infoSection {
    margin-bottom: 1.5rem;
  }
  
  .infoTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .infoItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem;
    border-radius: var(--border-radius-sm);
    transition: var(--transition);
  }
  
  .infoItem:hover {
    background-color: var(--primary-light);
  }
  
  .infoIcon {
    color: var(--primary);
    flex-shrink: 0;
  }
  
  .infoText {
    color: #333;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  /* Location and Experience */
  .locationItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .locationIcon {
    color: var(--primary);
  }
  
  .locationText {
    color: var(--gray);
  }
  
  /* Tags and Skills */
  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .tag {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    background-color: var(--primary-light);
    color: var(--primary);
    padding: 0.5rem 0.75rem;
    border-radius: var(--border-radius-md);
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    transition: var(--transition);
  }
  
  .tag:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  
  .tagIcon {
    flex-shrink: 0;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .profileHeader {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      padding: 1.5rem 1rem;
    }
    
    .profileInfo {
      padding: 1rem 0 0 0;
      align-items: center;
    }
    
    .profileName {
      font-size: 1.75rem;
      margin-left: 0 !important;
    }
    
    .profileSubtitle, .profileEmail {
      margin-left: 0 !important;
    }
    
    .profileImage {
      width: 120px;
      height: 120px;
      margin-bottom: 1rem;
    }
    
    .tabNavigation {
      justify-content: center;
    }
    
    .tagsContainer {
      justify-content: center;
    }
  }
  
  @media (max-width: 576px) {
    .tabButton {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }
  }