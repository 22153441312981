/* Profile.css */
:root {
    --primary-color: #6600CC;
    --primary-light: #8533D9;
    --primary-dark: #4C0099;
    --white: #ffffff;
    --light-gray: #f5f5f5;
    --medium-gray: #e0e0e0;
    --dark-gray: #757575;
    --text-color: #333333;
  }
  
  #main-wrapper {
    display: flex;
    min-height: 100vh;
  }
  
  .page-wrapper {
    flex: 1;
    background-color: var(--light-gray);
    padding: 15px;
  }
  
  .profile-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  /* Profile Header */
  .profile-header {
    position: relative;
    margin-bottom: 30px;
  }
  
  .profile-header-background {
    height: 200px;
    background: linear-gradient(135deg, #9e4af7, #551ea3);
    position: relative;
  }
  
  .profile-header-content {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 0 30px;
    transform: translateY(-60px);
    margin-bottom: -40px;
    z-index: 1;
  }
  
  .profile-picture-container {
    position: relative;
    margin-right: 30px;
  }
  
  .profile-picture {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 6px solid #ffffff;
    background-color: #f5f5f5;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
  }
  
  .profile-picture:hover {
    transform: scale(1.02);
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-picture-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #9f3af7, #9055ff);
    color: #ffffff;
    font-size: 46px;
    font-weight: 600;
    letter-spacing: -2px;
  }
  
  .profile-picture-edit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #924af7;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease;
    z-index: 2;
  }
  
  .profile-picture-edit:hover {
    background-color: #8536d9;
    transform: scale(1.1);
  }
  
  .user-info {
    padding-bottom: 10px;
  }
  
  .user-info h1 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: -1px;
    color: #333333;
  }
  
  .current-role {
    font-size: 16px;
    color: #666666;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .location {
    display: flex;
    align-items: center;
    color: #777777;
    font-size: 14px;
  }
  
  .location-icon {
    margin-right: 5px;
    color: #9b4af7;
  }
  
  .edit-button-container {
    position: absolute;
    top: 210px;
    right: 30px;
    z-index: 2;
  }
  
  .edit-button {
    background-color: #4a6cf7;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(74, 108, 247, 0.3);
    transition: all 0.2s ease;
  }
  
  .edit-button:hover {
    background-color: #3652d9;
    transform: translateY(-2px);
    box-shadow: 0 5px 12px rgba(74, 108, 247, 0.4);
  }
  
  .edit-button-icon {
    margin-right: 8px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .profile-header-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .profile-picture-container {
      margin-right: 0;
      margin-bottom: 20px;
    }
    
    .edit-button-container {
      position: relative;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .location {
      justify-content: center;
    }
  }
  
  .edit-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--white);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 30px;
    padding: 8px 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .edit-button:hover {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .edit-button-icon {
    stroke-width: 2;
  }
  
  /* Tab Container */
  .tab-container {
    border-bottom: 1px solid var(--medium-gray);
    padding: 0 20px;
  }
  
  .tab-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    scrollbar-width: none; /* Firefox */
  }
  
  .tab-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  
  .tab-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px 16px;
    background: transparent;
    border: none;
    color: var(--dark-gray);
    font-weight: 500;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.2s ease;
  }
  
  .tab-button.active {
    color: var(--primary-color);
  }
  
  .tab-button.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 3px 3px 0 0;
  }
  
  .tab-icon {
    stroke-width: 2;
  }
  
  /* Content Area */
  .content-area {
    padding: 30px;
  }
  
  /* Info Grid */
  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  @media (max-width: 992px) {
    .info-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .info-card {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
  }
  
  .info-card h3 {
    color: var(--primary-color);
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .info-card p {
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .detail-item {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  
  .detail-item-icon {
    color: var(--primary-color);
    margin-right: 15px;
    margin-top: 2px;
    flex-shrink: 0;
  }
  
  .detail-item label {
    display: block;
    font-size: 13px;
    color: var(--dark-gray);
    margin-bottom: 4px;
  }
  
  .detail-item-value {
    margin: 0;
    color: var(--text-color);
    font-weight: 500;
  }
  
  .detail-item input,
  .detail-item select,
  .info-card textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--medium-gray);
    border-radius: 6px;
    color: var(--text-color);
    transition: border-color 0.2s ease;
  }
  
  .detail-item input:focus,
  .detail-item select:focus,
  .info-card textarea:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 2px rgba(102, 0, 204, 0.1);
  }
  
  .info-card textarea {
    resize: none;
    height: 120px;
  }
  
  /* Timeline */
  .timeline {
    position: relative;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  
  .timeline-dot {
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--primary-color);
    border: 3px solid var(--white);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    left: 6px;
    top: 14px;
    bottom: 0;
    width: 2px;
    background-color: var(--medium-gray);
  }
  
  .timeline-card {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
  }
  
  .timeline-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .timeline-header h3 {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .company {
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .date {
    color: var(--dark-gray);
    font-size: 14px;
    white-space: nowrap;
  }
  
  /* Skills */
  .skills-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  @media (max-width: 992px) {
    .skills-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  .skill-tag {
    background-color: rgba(102, 0, 204, 0.1);
    color: var(--primary-color);
    border-radius: 20px;
    font-size: 13px;
    padding: 6px 14px;
    font-weight: 500;
  }
  
  .skill-input-container {
    margin-top: 15px;
  }
  
  .skill-input-wrapper {
    display: flex;
    gap: 10px;
  }
  
  .skill-input-wrapper input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid var(--medium-gray);
    border-radius: 6px;
  }
  
  .skill-input-wrapper button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .skill-input-wrapper button:hover {
    background-color: var(--primary-dark);
  }
  
  .language-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .language-icon {
    color: var(--primary-color);
    margin-right: 10px;
  }
  
  .certification-grid {
    margin-top: 15px;
  }
  
  .certification-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .certification-icon {
    color: var(--primary-color);
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .certification-item h4 {
    font-size: 16px;
    margin-bottom: 4px;
    color: var(--text-color);
  }
  
  .certification-item p {
    color: var(--dark-gray);
    font-size: 13px;
    margin: 0;
  }
  
  /* Resume Toggle */
  .resume-toggle-button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.2s ease;
  }
  
  .resume-toggle-button:hover {
    background-color: var(--primary-dark);
  }
  
  .resume-content {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .resume-section {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--medium-gray);
  }
  
  .resume-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .resume-section h3 {
    color: var(--primary-color);
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .experience-item {
    margin-bottom: 15px;
  }
  
  .experience-item h4 {
    font-size: 16px;
    margin-bottom: 4px;
    color: var(--text-color);
  }
  
  .experience-item p {
    color: var(--dark-gray);
    margin: 0 0 5px 0;
  }