/* DownloadButtons.css */
.download-container {
    background-color: white;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(102, 0, 204, 0.08);
    margin: 20px 0;
    transition: all 0.3s ease;
  }
  
  .download-container:hover {
    box-shadow: 0 6px 16px rgba(102, 0, 204, 0.12);
  }
  
  .download-header {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .download-header h5 {
    font-weight: 600;
    color: #6600CC;
    margin-bottom: 8px;
  }
  
  .download-header p {
    color: #666;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .download-buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
  
  .btn-download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s ease;
    font-size: 15px;
  }
  
  .btn-purple {
    background-color: #6600CC;
    color: white;
    border: none;
  }
  
  .btn-purple:hover {
    background-color: #5500aa;
    transform: translateY(-2px);
  }
  
  .btn-outline-purple {
    background-color: white;
    color: #6600CC;
    border: 1.5px solid #6600CC;
  }
  
  .btn-outline-purple:hover {
    background-color: rgba(102, 0, 204, 0.05);
    color: #5500aa;
    transform: translateY(-2px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 576px) {
    .download-buttons {
      flex-direction: column;
    }
    
    .btn-download {
      width: 100%;
    }
  }