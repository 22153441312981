.caliber-section {
    background-color: #fff; /* White background */
    padding: 60px 0;
  }
  
  .caliber-subtitle {
    color: #6600cc; /* Theme color */
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .caliber-title {
    color: #4D4D4D; /* Dark text for contrast */
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  
  .caliber-description {
    color: #666; /* Slightly lighter text for the description */
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 90%;
  }
  
  .caliber-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-placeholder img {
    box-shadow: 0 4px 15px rgba(102, 0, 204, 0.2); /* Subtle shadow with theme color */
    transition: transform 0.3s ease;
  }
  
  .image-placeholder img:hover {
    transform: scale(1.05); /* Slight zoom on hover for interactivity */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .caliber-title {
      font-size: 2rem;
    }
  
    .caliber-description {
      font-size: 1rem;
      max-width: 100%;
    }
  
    .caliber-image {
      margin-top: 30px;
    }
  }