/* Main Theme Colors */
:root {
  --primary-color: #6600CC;
  --primary-dark: #5500aa;
  --primary-light: #8833dd;
  --white: #ffffff;
  --light-gray: #f5f5f7;
  --border-color: #e2e2e2;
  --text-primary: #333333;
  --text-secondary: #666666;
  --shadow: 0 4px 12px rgba(102, 0, 204, 0.1);
  --transition: all 0.3s ease;
}

/* Reset and Base Styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--light-gray);
  color: var(--text-primary);
}

/* Layout and Container Styles */
#main-wrapper {
  display: flex;
}

.page-wrapper {
  flex: 1;
  min-height: 100vh;
}

.content-wrapper {
  padding: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .content-wrapper {
    padding: 2rem;
  }
}

/* Card Styles */
.card {
  background: var(--white);
  border-radius: 12px;
  box-shadow: var(--shadow);
  border: none;
  overflow: hidden;
  margin-bottom: 2rem;
  transition: var(--transition);
}

.card:hover {
  box-shadow: 0 6px 18px rgba(102, 0, 204, 0.15);
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #6600cc !important;
  margin: 0;
}

.card-content {
  padding: 1.5rem;
}

/* Form Element Styles */
.form-section {
  margin-bottom: 2rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-light);
}

.section-header h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0;
}

.input, .textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 0.9rem;
  transition: var(--transition);
  background-color: var(--white);
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.input:focus, .textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.1);
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
  color: var(--text-secondary);
}

/* Button Styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
  border: none;
  outline: none;
}

.button-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.button-primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.button-secondary {
  background-color: var(--white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button-secondary:hover {
  background-color: rgba(102, 0, 204, 0.05);
}

.button-destructive {
  background-color: #ff3b30;
  color: var(--white);
}

.button-destructive:hover {
  background-color: #e0352b;
}

.button-sm {
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
}

/* File Upload Styles */
.photo-upload-container, .signature-upload-container {
  margin-bottom: 1.5rem;
}

.photo-preview-container, .signature-preview-container {
  position: relative;
  margin-bottom: 1rem;
}

.photo-preview, .signature-preview {
  display: inline-block;
  position: relative;
}

.photo-preview img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--primary-light);
}

.signature-preview img {
  max-height: 80px;
  max-width: 200px;
  object-fit: contain;
}

.remove-photo, .remove-signature {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 1;
  padding: 0.25rem;
  border-radius: 50%;
}

.photo-placeholder, .signature-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px dashed var(--primary-light);
  cursor: pointer;
  color: var(--primary-color);
  transition: var(--transition);
  background-color: rgba(102, 0, 204, 0.05);
}

.photo-placeholder:hover, .signature-placeholder:hover {
  background-color: rgba(102, 0, 204, 0.1);
}

.signature-placeholder {
  width: 200px;
  height: 80px;
  border-radius: 8px;
}

/* Resume Preview Styles */
.preview-container {
  position: sticky;
  top: 2rem;
}

.resume-preview {
  background: var(--white);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: var(--shadow);
  min-height: 400px;
  margin-bottom: 1.5rem;
}

.preview-contact {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

/* Theme Styles */
.classic-theme {
  font-family: 'Times New Roman', serif;
}

.modern-theme {
  font-family: 'Roboto', sans-serif;
}

.minimal-theme {
  font-family: 'Montserrat', sans-serif;
}

.creative-theme {
  font-family: 'Poppins', sans-serif;
}

/* Theme Selector */
.theme-selector {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.theme-option {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  border: 2px solid var(--border-color);
  transition: var(--transition);
  font-size: 0.9rem;
}

.theme-option:hover {
  border-color: var(--primary-light);
}

.theme-option.selected {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

/* SideBar & Header Styles */
.sidebar {
  width: 250px;
  background: var(--primary-color);
  color: var(--white);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: var(--transition);
}

.header {
  padding: 1rem 2rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-buttons {
  display: flex;
  gap: 0.5rem;
}

/* Skills pill styling */
.skill-pill {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  background-color: rgba(102, 0, 204, 0.1);
  color: var(--primary-color);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  transition: var(--transition);
}

.skill-pill:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

/* Utility Classes */
.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.gap-2 {
  gap: 0.5rem;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}


