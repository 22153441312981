/* Resume Preview Themes */

/* Common preview styles */
.resume-preview {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: system-ui, -apple-system, sans-serif;
}

/* Classic Theme */
.resume-preview.classic-theme {
  background-color: white;
}

.classic-theme h1 {
  color: #2c3e50;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.classic-theme .preview-contact {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.classic-theme h2 {
  color: #2c3e50;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 0.25rem;
  margin: 1.5rem 0 1rem 0;
}

/* Modern Theme */
.resume-preview.modern-theme {
  background-color: white;
}

.modern-theme h1 {
  color: #1a237e;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.modern-theme .preview-contact {
  color: #303f9f;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.modern-theme h2 {
  color: #1a237e;
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 1rem;
  border-left: 4px solid #3949ab;
  margin: 2rem 0 1rem 0;
}

/* Minimal Theme */
.resume-preview.minimal-theme {
  background-color: white;
  max-width: 700px;
}

.minimal-theme h1 {
  color: #333;
  font-size: 1.75rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.minimal-theme .preview-contact {
  color: #666;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  margin-bottom: 2rem;
}

.minimal-theme h2 {
  color: #666;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 2rem 0 1rem 0;
}

/* Creative Theme */
.resume-preview.creative-theme {
  background-color: white;
}

.creative-theme h1 {
  color: #2e1437;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #2e1437, #7b4397);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creative-theme .preview-contact {
  color: #4b2555;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.creative-theme h2 {
  color: #2e1437;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 2rem 0 1rem 0;
  position: relative;
}

.creative-theme h2::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #2e1437, #7b4397);
  border-radius: 1.5px;
}

/* Shared section styles */
.resume-preview .preview-item {
  margin-bottom: 1.5rem;
}

.resume-preview .preview-item h3 {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.resume-preview .preview-item p {
  color: #666;
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

/* Skills section */
.resume-preview .skills-section span {
  display: inline-block;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* Photo styles */
.resume-preview .profile-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.classic-theme .profile-photo {
  border-radius: 4px;
}

.modern-theme .profile-photo {
  border-radius: 50%;
}

.minimal-theme .profile-photo {
  border-radius: 4px;
  width: 100px;
  height: 100px;
}

.creative-theme .profile-photo {
  border-radius: 12px;
  width: 100px;
  height: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Signature styles */
.resume-preview .signature {
  max-height: 60px;
  margin-top: 2rem;
}