/* UploadVideo.css */
:root {
  --primary-color: #6600CC;
  --primary-light: #9966FF;
  --primary-dark: #4B0082;
  --success-color: #28a745;
  --warning-color: #FFC107;
  --danger-color: #DC3545;
  --light-grey: #f8f9fa;
  --medium-grey: #e9ecef;
  --dark-grey: #6c757d;
  --white: #ffffff;
  --shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  --shadow-lg: 0 10px 25px rgba(0, 0, 0, 0.1);
  --border-radius: 0.75rem;
}

/* Layout styles */
.content-wrapper {
  padding: 20px;
  min-height: calc(100vh - 70px);
  background-color: var(--light-grey);
}

.page-title {
  font-weight: 600;
  color: #333;
}

/* Custom button styles */
.btn-purple {
  background-color: var(--primary-color);
  color: white;
  border: none;
  transition: all 0.2s ease;
}

.btn-purple:hover, .btn-purple:focus {
  background-color: var(--primary-dark);
  color: white;
  box-shadow: var(--shadow);
}

.btn-outline-purple {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  transition: all 0.2s ease;
}

.btn-outline-purple:hover, .btn-outline-purple:focus {
  background-color: var(--primary-color);
  color: white;
}

.btn-analyze {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: all 0.2s ease;
}

.btn-analyze:hover {
  background-color: var(--primary-dark);
  box-shadow: var(--shadow);
}

.btn-record {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: all 0.2s ease;
}

.btn-record:hover {
  background-color: var(--primary-dark);
  box-shadow: var(--shadow);
}

.btn-stop {
  background-color: var(--danger-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: all 0.2s ease;
}

.btn-stop:hover {
  background-color: #c82333;
  box-shadow: var(--shadow);
}

/* Method toggle styles */
.method-toggle-container {
  padding: 10px 0;
}

.method-toggle .toggle-btn {
  font-weight: 500;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.method-toggle .btn-group {
  border-radius: var(--border-radius);
  overflow: hidden;
}

/* Upload area styles */
.upload-container {
 
  transition: all 0.3s ease;
}

.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-radius: var(--border-radius);
  background-color: var(--white);
  transition: all 0.3s ease;
  min-height: 300px;
}

.upload-area.dragging {
  background-color: rgba(102, 0, 204, 0.05);
  border: 2px dashed var(--primary-color);
}

.upload-icon-container {
  margin-bottom: 1rem;
  background-color: rgba(102, 0, 204, 0.1);
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 30px;
}

.upload-divider {
  font-weight: 500;
  color: var(--dark-grey);
  margin: 0.75rem 0;
}

.upload-btn {
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: all 0.2s ease;
  font-weight: 500;
}

.pulse-animation {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.small-text {
  font-size: 0.8rem;
}

/* Recording container styles */
.recording-container {
  border-radius: var(--border-radius);
  background-color: var(--white);
  overflow: hidden;
}

.video-preview-container {
  position: relative;
  background-color: #000;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-preview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}

.recording-indicator {
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 12px;
  border-radius: 50px;
  font-size: 0.85rem;
}

.recording-dot {
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  border-radius: 50%;
  margin-right: 8px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

.recording-controls {
  background-color: var(--white);
}

.recording-complete-text {
  display: flex;
  align-items: center;
  color: var(--success-color);
  font-weight: 500;
}

/* Selected video container */
.selected-video-container {
  background-color: var(--white);
  border-radius: var(--border-radius);
  transition: all 0.3s ease;
}

.video-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #333;
}

.thumbnail-preview {
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  background-color: #000;
  height: 200px;
}

/* Analysis container styles */
.analysis-container {
  height: auto;
  background-color: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.analysis-header {
  display: flex;
  align-items: center;
  background-color: rgba(102, 0, 204, 0.05);
  padding: 1.25rem;
  border-bottom: 1px solid rgba(102, 0, 204, 0.1);
}

.metrics-container {
  padding: 1.5rem;
  max-height: 500px;
  overflow-y: auto;
}

.metrics-list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.metric-row {
  transition: all 0.2s ease;
}

.metric-row:hover {
  transform: translateY(-2px);
}

.metric-label-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.metric-label {
  font-weight: 600;
  color: #333;
}

.metric-value {
  font-weight: 700;
}

.text-high {
  color: var(--primary-color);
}

.text-good {
  color: var(--primary-light);
}

.text-medium {
  color: var(--warning-color);
}

.text-low {
  color: var(--danger-color);
}

.metric-bar-container {
  height: 10px;
  background-color: var(--medium-grey);
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}

.metric-bar {
  height: 100%;
  border-radius: 50px;
  position: relative;
  transition: width 0.5s ease-in-out;
}

.metric-tooltip {
  position: absolute;
  top: -35px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  font-size: 0.8rem;
  transform: translateX(50%);
  white-space: nowrap;
}

.metric-tooltip:after {
  content: '';
  position: absolute;
  bottom: -5px;
  right: 50%;
  transform: translateX(50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1.5rem;
  text-align: center;
  color: var(--dark-grey);
  min-height: 250px;
}

.empty-icon {
  margin-bottom: 1rem;
  opacity: 0.5;
}

.empty-title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.empty-description {
  color: var(--dark-grey);
  max-width: 300px;
  margin: 0 auto 1rem;
}

.empty-action {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-weight: 500;
}

/* Loading animation */
.loading-animation {
  margin-bottom: 1rem;
}

.loading-text {
  color: var(--dark-grey);
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .upload-area {
    padding: 2rem 1rem;
  }
  
  .method-toggle .toggle-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
  
  .video-preview-container {
    height: 250px;
  }
}

/* Text color for purple theme */
.text-purple {
  color: var(--primary-color) !important;
}