/* ChangePassword.css */
.change-password-container {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 65vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .change-password-container .change-password-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .change-password-container  .change-password-form h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }
  
  .change-password-container  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .change-password-container  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
    font-size: 0.9rem;
  }
  
  .change-password-container  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .change-password-container  .form-group input:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  .change-password-container  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .change-password-container  .submit-button:hover {
    background-color: #357abd;
  }
  
  .change-password-container  .submit-button:active {
    transform: translateY(1px);
  }
  
  .change-password-container  .message {
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .change-password-container  .message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .change-password-container  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }