/* CourseDetails.module.css */

/* Global Reset and Base Styles */
:global(*) {
  box-sizing: border-box;
  transition: all 0.3s ease;
  margin: 0;
  padding: 0;
}

.courseDetailsWrapper {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  color: #1e293b;
  line-height: 1.6;
}

/* Hero Section */
.courseHero {
  background-color: #1e2230;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
}

.courseHero::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(102,0,204,0.1) 0%, transparent 70%);
  z-index: 1;
}

.heroContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 2;
}

.courseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.coursePath span {
  color: rgba(255,255,255,0.7);
  font-size: 0.85rem;
  margin-right: 0.5rem;
}

.pathLink {
  color: #6600CC;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  transition: color 0.3s ease;
}

.pathLink::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6600CC;
  transition: width 0.3s ease;
}

.pathLink:hover::after {
  width: 100%;
}

.courseActions .btnIcon {
  background: rgba(102,0,204,0.2);
  border: none;
  color: rgba(255,255,255,0.8);
  margin-left: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.courseActions .btnIcon:hover {
  background: rgba(102,0,204,0.3);
  color: #ffffff;
  transform: scale(1.1);
}

.courseTitleSection h1 {
  font-size: 2.2rem;
  margin: 0.5rem 0;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
}

.courseMeta {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.author {
  color: #ffffff;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.courseRating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.courseRating span {
  color: #ffc107;
  font-size: 0.9rem;
}

.ratingText {
  color: rgba(255,255,255,0.7);
  font-size: 0.8rem;
}

.description {
  color: rgba(255,255,255,0.9);
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
}

.courseInfo {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoItem span {
  color: rgba(255,255,255,0.7);
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.infoItem strong {
  color: #ffffff;
  font-weight: 500;
  font-size: 0.95rem;
}

.resumeButton {
  background-color: #6600CC;
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-weight: 600;
  border-radius: 0.75rem;
  font-size: 0.95rem;
  margin-top: 1.5rem;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.resumeButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(102,0,204,0.4);
  background-color: #7700ee;
}

/* Course Tabs */
.courseTabs {
  background-color: #ffffff;
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.navTabs {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  gap: 2rem;
  justify-content: center;
  max-width: 1200px;
}

.navLink {
  background: none;
  border: none;
  color: #64748b;
  padding: 1rem 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.95rem;
  position: relative;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #6600CC;
}

.navLinkActive {
  color: #6600CC;
  font-weight: 600;
}

.navLinkActive::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #6600CC;
  border-radius: 2px;
}

/* Course Content */
.courseContent {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.contentHeader h2 {
  color: #1e293b;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.expandButton {
  color: #6600CC;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.expandButton:hover {
  color: #7700ee;
  text-decoration: underline;
}

/* Module Card */
.moduleCard {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  overflow: hidden;
  transition: all 0.3s ease;
}

.moduleCard:hover {
  box-shadow: 0 4px 10px rgba(102,0,204,0.1);
  transform: translateY(-2px);
}

.moduleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}

.moduleHeader:hover {
  background-color: #f1f3f5;
}

.moduleTitleSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.moduleOrder {
  background-color: #6600CC;
  color: #ffffff;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.moduleTitle {
  color: #1e293b;
  font-weight: 600;
}

.moduleMeta {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #64748b;
  font-size: 0.9rem;
}

.moduleDuration {
  color: #64748b;
}

.moduleDescription {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border-top: 1px solid #e2e8f0;
}

.moduleDescription p {
  margin: 0.5rem 0;
  color: #64748b;
}

.videoList {
  background-color: #f8fafc;
  padding: 0.5rem 0;
}

.videoList.show {
  display: block;
}

.videoList.hide {
  display: none;
}

.videoItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  padding-left: 3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-left: 3px solid transparent;
}

.videoItem:hover {
  background-color: #f1f5f9;
  border-left-color: #6600CC;
}

.videoTitleSection {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #1e293b;
}

.videoTitle {
  font-size: 0.9rem;
}

.videoMeta {
  color: #64748b;
  font-size: 0.85rem;
}

/* Sidebar */
.courseInfoContainer {
  background-color: #FFFFFF;
  padding: 1.5rem;
  height: 100vh !important;
  display: inline-block !important;
  color: #1e293b;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  position: fixed;
}

.courseAuthor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.authorImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 3px solid rgba(102,0,204,0.3);
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.authorName {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #1e293b;
}

.authorDescription {
  font-size: 0.85rem;
  text-align: center;
  color: #64748b;
  line-height: 1.5;
}

.courseDetails {
  border-top: 1px solid #e2e8f0;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.courseDetails h3 {
  color: #6600CC;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.detailLabel {
  color: #64748b;
}

.detailValue {
  text-align: right;
  color: #1e293b;
  font-weight: 500;
}

.rating {
  color: #ffc107;
  display: inline-block;
}

.shareIcons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1.5rem;
}

.shareIcon {
  color: #64748b;
  font-size: 1.3rem;
  text-decoration: none;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.shareIcon:hover {
  opacity: 1;
  transform: scale(1.2);
  color: #6600CC;
}

/* Loading and Error States */
.loadingText, 
.errorText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: #64748b;
  font-size: 1.2rem;
}

/* Accessibility and Interaction Enhancements */
.moduleHeader:focus,
.videoItem:focus {
  outline: 2px solid #6600CC;
  outline-offset: -2px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .courseInfo {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .navTabs {
    overflow-x: auto;
    justify-content: flex-start;
    padding: 0 1rem;
  }

  .heroContent {
    padding: 0 0.75rem;
  }

  .courseTitleSection h1 {
    font-size: 1.8rem;
  }
}