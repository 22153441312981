/* Base styles remain largely the same */
.register-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.register-popup-container {
  background-color: #fff;
  width: 90%;
  max-width: 700px;
  border-radius: 16px; /* Softer corners */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15); /* Softer shadow */
  overflow: hidden;
  animation: slideUp 0.4s ease;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.register-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.register-popup-header h3 {
  margin: 0;
  font-size: 18px; /* Slightly smaller for mobile */
  font-weight: 600;
  color: #222;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.close-button:hover {
  background-color: #f0f0f0;
}

.register-popup-body {
  padding: 16px;
}

.form-group {
  margin-bottom: 16px; /* More breathing room */
}

.form-label {
  font-weight: 500;
  color: #333;
  margin-bottom: 6px;
  display: block;
  font-size: 13px; /* Slightly smaller for mobile */
}

.form-control,
.form-select {
  height: 38px; /* Slightly smaller inputs */
  border: 1px solid #e0e0e0;
  border-radius: 8px; /* Softer edges */
  padding: 8px 12px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  font-size: 14px;
  background-color: #fafafa; /* Light background for inputs */
}

.input-group {
  display: flex;
}

.input-group-text {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-right: none;
  border-radius: 8px 0 0 8px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.input-group .form-control {
  border-radius: 0 8px 8px 0;
}

.form-control:focus,
.form-select:focus {
  border-color: #0b8a0b; /* Match your brand color */
  box-shadow: 0 0 0 2px #beffbe;
  outline: none;
}

.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  height: 38px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #444;
}

.btn-cancel:hover {
  background-color: #e0e0e0;
}

.btn-register {
  background-color: #6600CC;
  color: white;
  min-width: 100px;
}

.btn-register:hover {
  background-color: #5500aa;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .register-popup-container {
    width: 90%;
    max-height: 85vh; /* Leave some space at top/bottom */
    overflow-y: auto;
    margin: 0 16px; /* Center with some margin */
  }

  .register-popup-header {
    padding: 10px 14px;
  }

  .register-popup-header h3 {
    font-size: 16px;
  }

  .register-popup-body {
    padding: 14px;
  }

  .form-group {
    margin-bottom: 14px;
  }

  .form-control,
  .form-select {
    height: 36px; /* Even smaller for mobile */
    font-size: 13px;
  }

  .form-label {
    font-size: 12px;
  }

  .form-actions {
    flex-direction: row; /* Keep buttons side by side */
    justify-content: space-between; /* Spread them out */
  }

  .btn {
    flex: 1; /* Buttons take equal space */
    height: 36px;
    font-size: 12px;
    padding: 0 12px;
  }

  .btn-register {
    min-width: 0; /* Allow it to shrink */
  }
}

/* Extra small screens (below 480px) */
@media (max-width: 480px) {
  .register-popup-container {
    width: 95%;
    border-radius: 12px;
  }

  .form-actions {
    flex-direction: column; /* Stack buttons on very small screens */
    gap: 8px;
  }

  .btn {
    width: 100%; /* Full-width buttons */
  }
}

@media (max-width: 768px) {
  .input-group {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input-group-text {
    padding: 6px 8px; /* Reduce padding for smaller screens */
    border-radius: 6px 0 0 6px;
  }

  .input-group .form-control {
    flex: 1;
    border-radius: 0 6px 6px 0;
  }

  .input-group-text svg {
    width: 16px; /* Smaller icon */
    height: 16px;
  }
}
