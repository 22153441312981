.accelerate-section {
    /* Added a hint of green in the gradient */
    background: linear-gradient(135deg, #6f04da 0%, #410490 50%, #218854 100%);
    color: white;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .accelerate-title {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    color: white;
  }
  
  .accelerate-subtitle {
    font-size: 1.1rem;
    opacity: 0.9;
    margin-bottom: 30px;
  }
  
  .get-started-btn {
    background-color: #ff3366;
    border: 2px solid #33cc99; /* Green border */
    padding: 10px 30px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 50px;
    transition: all 0.3s ease;
  }
  
  .get-started-btn:hover {
    background-color: #e62e5c;
    border-color: #2eb88a; /* Slightly darker green on hover */
    transform: scale(1.05); /* Slight scale effect for interactivity */
  }
  
  .video-section {
    padding: 40px;
    position: relative;
  }
  
  .video-placeholder {
    background-size: cover;
    height: 400px;
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
  
  
  .expert-badge {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #33cc99; /* Green background for the badge */
    border-radius: 20px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .expert-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .expert-info {
    display: flex;
    flex-direction: column;
  }
  
  .expert-name {
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff; /* White text for contrast on green background */
  }
  
  .expert-title {
    font-size: 0.8rem;
    color: #e6f7f2; /* Light green tint for the title */
  }