.great-learning-container {
  padding: 80px 0;
  background-color: #fff;
}

.section-title {
  color: #6600CC;
  font-weight: 700;
  margin-bottom: 50px;
  position: relative;
}

/* Image handling improvements */
.image-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 15px;
}

.image-container {
  width: 100%;
  max-width: 600px;
  height: 350px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: translateY(-5px);
}

.features-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content-block {
  padding: 20px;
}

.feature-title {
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.4;
}

.feature-text {
  color: #666;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.6;
}

.great-learning-btn {
  background-color: #6600CC;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.great-learning-btn:hover {
  background-color: #5500aa;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(102, 0, 204, 0.3);
}

.hiring-mode {
  margin-bottom: 20px;
}

.hiring-mode h4 {
  color: #6600CC;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline;
}

.hiring-mode p {
  color: #666;
  display: inline;
  margin-left: 5px;
}

.request-callback {
  background-color: white;
  color: #6600CC;
  border: 1px solid #6600CC;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.request-callback:hover {
  background-color: #f7f7f7;
  box-shadow: 0 2px 10px rgba(102, 0, 204, 0.2);
}

/* Responsive styles */
@media (max-width: 992px) {
  .great-learning-container {
    padding: 50px 0;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .row {
    margin-bottom: 40px;
  }
  
  .image-wrapper {
    margin-bottom: 20px;
  }
  
  .content-block {
    text-align: center;
    padding: 10px 20px 30px;
  }
  
  .feature-title {
    font-size: 20px;
  }
  
  .image-container {
    height: 250px;
    width: 100%;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .image-container {
    height: 220px;
  }
  
  .feature-title {
    font-size: 18px;
  }
  
  .feature-text {
    font-size: 15px;
  }
  
  .hiring-mode {
    text-align: left;
    padding: 0 10px;
  }
  
  .hiring-mode h4, 
  .hiring-mode p {
    display: block;
    margin-left: 0;
  }
  
  .hiring-mode p {
    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .great-learning-container {
    padding: 40px 0;
  }
  
  .image-container {
    height: 200px;
  }
}