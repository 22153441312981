.all-mentor-wrapper {
  background-color: #f8f9fa;
  min-height: 100vh;
  position: relative;
}
.all-mentor-content {
  max-width: 1400px;
  margin: 0 auto;
}

.content-wrapper h4 {
  color: #9339edd0;
  font-weight: 600;
  margin-top: 45px;
  margin-bottom: 2rem;
}

/* Search and Filter Styles */
.form-select,
.form-control {
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 8px;
}

.form-select:focus,
.form-control:focus {
  border-color: #6600cc;
  box-shadow: 0 0 0 0.2rem rgba(102, 0, 204, 0.25);
}

.btn-primary {
  background-color: #6600cc;
  border-color: #6600cc;
}

.btn-primary:hover {
  background-color: #5500aa;
  border-color: #5500aa;
}

.btn-outline-primary {
  color: #6600cc;
  border-color: #6600cc;
}

.btn-outline-primary:hover {
  background-color: #6600cc;
  color: white;
}

/* Mentor Card Styles */
.mentor-card {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mentor-card:hover {
  transform: translateY(-5px);
}

.mentor-photo {
  width: 120px;
  height: 120px;
  margin: 0 auto 1.5rem;
  overflow: hidden;
}

.mentor-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mentor-name {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.mentor-industry {
  color: #6600cc;
  font-size: 0.9rem;
  font-weight: 500;
  display: block;
  margin-bottom: 1rem;
}

.mentor-description {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  flex-grow: 1;
}

.mentor-actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: auto;
}

.mentor-actions button {
  flex: 1;
}

/* Connect Dropdown Styles */
.connect-wrapper {
  position: relative;
  flex: 1;
}

.connect-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 180px;
  z-index: 1000;
  padding: 0.5rem 0;
  border: 1px solid #e0e0e0;
}

.connect-option {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #333;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  text-align: left;
}

.connect-option:hover {
  background-color: #f0f0f0;
}

.connect-option svg {
  color: #6600cc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 1rem;
  }

  .mentor-card {
    margin-bottom: 1rem;
  }

  .connect-dropdown {
    position: absolute;
    width: 160px;
  }
}
