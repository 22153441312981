.strategy-section {
    background: linear-gradient(135deg, #fff 0%, #f0eaff 100%); /* Light gradient with white and a hint of purple */
    position: relative;
  }
  
  .strategy-title {
    color: #4D4D4D; /* Dark text for contrast */
    font-size: 2rem; /* Slightly smaller title */
  font-weight: 700;
    line-height: 1.3;
  }
  
  .strategy-cards {
    display: flex;
    justify-content: space-between;
  }
  
  .strategy-card {
    background-color: #fff; /* White background for cards */
    border-radius: 10px; /* Slightly smaller border radius */
    padding: 15px; /* Reduced padding */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Smaller shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 88%;
  }
  
  .strategy-card:hover {
    transform: translateY(-3px); /* Reduced lift on hover */
    box-shadow: 0 6px 15px rgba(102, 0, 204, 0.2); /* Slightly smaller shadow on hover */
  }
  
  .strategy-icon {
    color: #6600cc; /* Theme color */
    margin-bottom: 10px;
  }
  
  .strategy-icon svg {
    width: 30px; /* Smaller icon size */
    height: 30px;
  }
  
  .strategy-label {
    display: inline-block;
    background-color: #28a745; /* Green accent */
    color: #fff;
    font-size: 0.8rem; /* Smaller font size for label */
    font-weight: 600;
    padding: 3px 8px; /* Reduced padding */
    border-radius: 10px;
    margin-bottom: 8px;
  }
  
  .strategy-card-title {
    color: #333;
    font-size: 1rem; /* Smaller font size for title */
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .strategy-card-description {
    color: #666; /* Lighter text for description */
    font-size: 0.92rem; /* Smaller font size for description */
    line-height: 1.4; /* Adjusted line height */
  }
  
  .strategy-results {
    margin-top: 30px; /* Reduced margin */
  }
  
  .result-item {
    padding: 15px; /* Reduced padding */
  }
  
  .result-value {
    color: #6600cc; /* Theme color */
    font-size: 2rem; /* Slightly smaller font size */
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .result-description {
    color: #666;
    font-size: 0.9rem; /* Smaller font size */
    line-height: 1.4;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .strategy-title {
      font-size: 1.8rem; /* Smaller title on mobile */
    }
  
    .strategy-card {
      padding: 10px; /* Further reduced padding on mobile */
    }
  
    .strategy-card-title {
      font-size: 0.9rem;
    }
  
    .strategy-card-description {
      font-size: 0.75rem;
    }
  
    .result-value {
      font-size: 1.8rem;
    }
  
    .result-description {
      font-size: 0.85rem;
    }
  }