/* MentorProfile.module.css */

:root {
  --primary: #6600CC;
  --primary-light: rgba(102, 0, 204, 0.1);
  --primary-medium: rgba(102, 0, 204, 0.2);
  --primary-dark: #5500AA;
  --white: #fff;
  --light-gray: #f8f9fa;
  --gray: #6c757d;
  --border-light: rgba(0, 0, 0, 0.08);
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 12px rgba(102, 0, 204, 0.15);
  --shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.12);
  --transition: all 0.3s ease;
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 15px;
  --border-radius-xl: 20px;
  --success: #4caf50;
  --warning: #ff9800;
  --danger: #f44336;
}

/* Main Layout */
.pageWrapper {
  background-color: var(--white);
  min-height: 100vh;
}

.contentContainer {
  padding: 2rem 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Profile Card */
.profileCard {
  border-radius: var(--border-radius-lg);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border: none;
  margin-bottom: 2rem;
  background-color: var(--white);
}

.profileHeader {
  padding: 2rem;
}

.profileName {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
  text-transform: capitalize;
}

.profileDescription {
  color: var(--gray);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.profileImage {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid var(--white);
  box-shadow: var(--shadow-md);
}

/* Button Styles */
.btnPrimary {
  background-color: var(--primary);
  color: var(--white);
  border: 2px solid var(--primary);
  border-radius: var(--border-radius-xl);
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  transition: var(--transition);
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.btnPrimary:hover {
  background-color: var(--white);
  color: var(--primary);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.btnOutline {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
  border-radius: var(--border-radius-xl);
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  transition: var(--transition);
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.btnOutline:hover {
  background-color: var(--primary);
  color: var(--white);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.btnSecondary {
  background-color: var(--gray);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-xl);
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  transition: var(--transition);
}

.btnSuccess {
  background-color: var(--success);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-xl);
  padding: 0.375rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btnSuccess:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Tab Navigation */
.tabNavigation {
  display: flex;
  border-bottom: 1px solid var(--border-light);
  margin-bottom: 1.5rem;
  padding: 0;
  overflow-x: auto;
}

.tabItem {
  list-style: none;
  margin-right: 1rem;
}

.tabButton {
  padding: 0.75rem 1.25rem;
  background: transparent;
  border: none;
  color: var(--gray);
  font-weight: 500;
  transition: var(--transition);
  position: relative;
  cursor: pointer;
}

.tabButton:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: transparent;
  transition: var(--transition);
}

.tabButtonActive {
  color: var(--primary);
}

.tabButtonActive:after {
  background-color: var(--primary);
}

/* About Section */
.infoCard {
  background-color: var(--white);
  border-radius: var(--border-radius-md);
  
  
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  border: none;
}

.infoCardTitle {
  font-weight: 700;
  margin-bottom: 1.25rem;
  color: white;
  font-size: 1.25rem;
}

.infoText {
  color: var(--gray);
  line-height: 1.6;
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.infoIcon {
  color: var(--primary);
  flex-shrink: 0;
}

.infoLabel {
  text-transform: capitalize;
  font-weight: 500;
  color: #333;
  font-size: 20px;
}

/* Skills Section */
.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.skillItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
}

.skillIcon {
  color: var(--primary);
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modalContent {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  width: 100%;
  max-width: 800px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border: 2px solid var(--primary);
}

.modalHeader {
  background-color: var(--primary);
  color: var(--white);
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalTitle {
  font-weight: 600;
  margin: 0;
  color: white;
}

.closeButton {
  background: transparent;
  border: none;
  color: var(--white);
  font-size: 1.5rem;
  cursor: pointer;
}

.modalBody {
  padding: 1.5rem;
  max-height: 70vh;
  overflow-y: auto;
}

.modalFooter {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--border-light);
}

/* Calendar Styles */
.calendarContainer {
  height: 500px;
  margin-bottom: 1.5rem;
}

.selectedTimeSlot {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: var(--primary-light);
  border-radius: var(--border-radius-md);
  border-left: 4px solid var(--primary);
}

.formGroup {
  margin-bottom: 1.25rem;
}

.formLabel {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--primary);
}

.formControl {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--primary-light);
  border-radius: var(--border-radius-md);
  transition: var(--transition);
}

.formControl:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px var(--primary-light);
}

/* Bookings List */
.bookingsList {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.bookingItem {
  padding: 1rem;
  border-radius: var(--border-radius-md);
  margin-bottom: 1rem;
  transition: var(--transition);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bookingPending {
  background-color: #fff8e1;
  border-left: 4px solid var(--warning);
}

.bookingAccepted {
  background-color: #e8f5e9;
  border-left: 4px solid var(--success);
}

.bookingRejected {
  background-color: #ffebee;
  border-left: 4px solid var(--danger);
}

.bookingCompleted {
  background-color: #f5f5f5;
  border-left: 4px solid var(--gray);
}

.bookingItem:hover {
  box-shadow: var(--shadow-sm);
}

.bookingHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.statusIndicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex-shrink: 0;
}

.statusPending {
  background-color: var(--warning);
}

.statusAccepted {
  background-color: var(--success);
}

.statusRejected {
  background-color: var(--danger);
}

.statusCompleted {
  background-color: var(--gray);
}

.bookingTitle {
  font-weight: 600;
  margin: 0;
}

.bookingReason {
  margin-bottom: 0.5rem;
}

.bookingStatus {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--white);
}

.statusBadgePending {
  background-color: var(--warning);
}

.statusBadgeAccepted {
  background-color: var(--success);
}

.statusBadgeRejected {
  background-color: var(--danger);
}

.statusBadgeCompleted {
  background-color: var(--gray);
}

/* Reviews List */
.reviewsList {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.reviewItem {
  padding: 1rem;
  border-radius: var(--border-radius-md);
  margin-bottom: 1rem;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: var(--transition);
}

.reviewItem:hover {
  box-shadow: var(--shadow-md);
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.reviewAuthor {
  font-weight: 600;
  margin: 0;
}

.reviewDate {
  color: var(--gray);
  font-size: 0.85rem;
}

.starRating {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.starFilled {
  color: #ffc107;
}

.starEmpty {
  color: #e0e0e0;
}

/* Recommended Mentors Section */
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.sectionTitle {
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.sectionBadge {
  background-color: var(--primary-light);
  color: var(--primary);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.mentorGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.mentorCard {
  border-radius: var(--border-radius-md);
  overflow: hidden;
  background-color: var(--white);
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  border: 1px solid var(--border-light);
  height: 100%;
}

.mentorCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
  border-color: var(--primary);
}

.mentorImageWrapper {
  position: relative;
  height: 160px;
  overflow: hidden;
}

.mentorImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.mentorCard:hover .mentorImage {
  transform: scale(1.05);
}

.mentorOverlay {
  position: absolute;
  inset: 0;
  background: rgba(102, 0, 204, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: var(--transition);
}

.mentorCard:hover .mentorOverlay {
  opacity: 1;
}

.availabilityBadge {
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 4px 10px;
  border-radius: 1rem;
  font-size: 0.75rem;
  color: var(--primary);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  box-shadow: var(--shadow-sm);
}

.mentorCardBody {
  padding: 1rem;
}

.mentorName {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
  text-transform: capitalize;
}

.mentorRating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.expertiseTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.expertiseTag {
  background-color: var(--primary-light);
  color: var(--primary);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  text-transform: capitalize;
}

/* No Mentor Assigned Section */
.emptyStateCard {
  text-align: center;
  padding: 3rem;
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
}

.emptyStateIcon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: var(--gray);
}

.emptyStateTitle {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.emptyStateText {
  color: var(--gray);
  max-width: 500px;
  margin: 0 auto 1.5rem;
}

/* Calendar Overrides - These will control FullCalendar */
:global(.fc .fc-button-primary) {
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: var(--border-radius-xl);
  padding: 0.375rem 1rem;
  font-weight: 500;
  transition: var(--transition);
}

:global(.fc .fc-button-primary:hover) {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--primary);
}

:global(.fc .fc-button-primary:not(:disabled).fc-button-active),
:global(.fc .fc-button-primary:not(:disabled):active) {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

:global(.fc .fc-daygrid-day.fc-day-today) {
  background-color: var(--primary-light);
}

:global(.fc-event) {
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: var(--transition);
}

:global(.fc-event:hover) {
  transform: scale(1.02);
  box-shadow: var(--shadow-sm);
}

:global(.fc-col-header-cell) {
  background-color: var(--primary-light);
}

:global(.fc-col-header-cell-cushion) {
  color: var(--primary);
  font-weight: 600;
  padding: 10px;
}

:global(.fc-timegrid-slot) {
  height: 40px !important;
}

:global(.fc-timegrid-now-indicator-line) {
  border-color: #f44336;
  border-width: 2px;
}

:global(.fc-timegrid-now-indicator-arrow) {
  border-color: #f44336;
}

/* Media Queries */
@media (max-width: 768px) {
  .profileHeader {
    padding: 1.5rem;
  }
  
  .profileImage {
    width: 120px;
    height: 120px;
    margin-bottom: 1rem;
  }
  
  .tabNavigation {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tabButton {
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }
  
  .mentorGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  :global(.fc-header-toolbar) {
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem !important;
  }
  
  :global(.fc-toolbar-chunk) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .contentContainer {
    padding: 1rem;
  }
  
  .profileName {
    font-size: 1.5rem;
  }
  
  .btnPrimary, .btnOutline {
    width: 100%;
    margin-right: 0;
  }
  
  .mentorGrid {
    grid-template-columns: 1fr;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
  color: white;
}